import React, { ReactNode, useCallback, useState } from "react";
import { UtilsIdentifier } from "../../../hotelchamp";
import { NotificationManagerContext } from "./NotificationManagerContext";
import { INotification, TNotificationData } from "./types";

export interface INotificationManagerProviderProps {
    children: ReactNode;
}

export function NotificationManagerProvider({ children }: INotificationManagerProviderProps) {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const pushNotification = (notification: TNotificationData) => {
        const id = UtilsIdentifier.uuid();

        setNotifications([...notifications, { ...notification, id }]);

        return id;
    };

    const removeNotification = (id: string) => {
        const removeNotificationIndex = notifications.findIndex((n) => n.id === id);

        if (removeNotificationIndex !== -1) {
            const newNotifications = [...notifications];

            newNotifications.splice(removeNotificationIndex, 1);

            setNotifications(newNotifications);
        }
    };

    const contextValue = {
        notifications,
        pushNotification: useCallback(pushNotification, [pushNotification]),
        removeNotification: useCallback(removeNotification, [removeNotification]),
    };

    return <NotificationManagerContext.Provider value={contextValue}>{children}</NotificationManagerContext.Provider>;
}
