import React, { HTMLAttributes } from "react";
import { twMerge } from "@app/common";
import { commonConstraints, commonTypes, commonUtils } from "../common";
import { fontSizes, fontWeights, textAlignments } from "./constraints";
import { IBaseText } from "./types";
export interface TitleProps extends IBaseText, Omit<HTMLAttributes<HTMLHeadingElement>, "color"> {
    color?: commonTypes.TColor;
    truncate?: boolean;
    marginTop?: commonTypes.TMarginTop;
    children: React.ReactNode;
}

export const Title = ({
    color = "gray",
    truncate = false,
    marginTop = "mt-0",
    weight = "light",
    alignment = "left",
    size = "xl",
    children,
    className,
    ...restProps
}: TitleProps) => (
    <h1
        className={twMerge(
            truncate ? "whitespace-nowrap truncate" : "shrink-0",
            marginTop,
            commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkText).textColor,
            fontWeights[weight],
            fontSizes[size],
            textAlignments[alignment],
            className
        )}
        {...restProps}>
        {children}
    </h1>
);
