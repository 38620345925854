import { LoadingMask } from "@ui";
import React from "react";
import { useGetUser, useLogin, useLogout } from "../hooks";
import { AuthContext } from "./AuthContext";

/**
 * AuthLoaderProvider
 * Decorates AuthContext.Provider with loader functionality
 * @param props
 * @returns
 */
export function AuthLoaderProvider(props: any) {
    const { data, error, isLoading, isIdle, isError } = useGetUser();

    const login = React.useCallback(useLogin, []);
    const logout = React.useCallback(useLogout, []);

    const value = React.useMemo(() => ({ ...data, login, logout }), [data, login, logout]);

    if (isLoading || isIdle) {
        return <LoadingMask isLoading={true} />;
    }

    if (isError) {
        return <div>Something wrong... {JSON.stringify(error)}</div>;
    }

    return <AuthContext.Provider value={value} {...props} />;
}
