import { AuthLoaderProvider } from "@app/auth";
import { NotificationCarousel, NotificationManagerProvider } from "@app/common";
import {} from "@app/common/components/notification/NotificationManagerProvider";
import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { i18next } from "@app/services";
import { I18nextProvider } from "react-i18next";
import { queryClient } from "../services/queryClient";
import { Routes } from "./Routes";

export const App = () => (
    <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
            <NotificationManagerProvider>
                <Router>
                    <AuthLoaderProvider>
                        <Routes />
                    </AuthLoaderProvider>
                </Router>
                <NotificationCarousel />
            </NotificationManagerProvider>
        </I18nextProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
);
