import { commonConstraints, commonTypes, commonUtils } from "@ui/common";
import { twMerge } from "@app/common";
import React, { HTMLAttributes } from "react";

interface IBadgeProps extends HTMLAttributes<HTMLSpanElement> {
    size?: Exclude<commonTypes.TSizes, "xs" | "md" | "xl">;
    color?: commonTypes.TColor;
    children: JSX.Element | string | number;
}

export const Badge = ({ color = "slate", size = "sm", children, className, ...restProps }: IBadgeProps) => {
    const getSize = (): string => {
        switch (size) {
            case "sm":
                return "px-2 py-0.5 text-xs font-medium";
            case "lg":
            default:
                return "px-2.5 py-0.5 text-sm font-medium";
        }
    };

    const getColor = () => {
        switch (color) {
            case "blue":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "purple":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkestBackground).bgColor,
                ];
            case "yellow":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "gray":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "green":
                return [
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkText).textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.lightBackground).bgColor,
                ];
            case "red":
                return [
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkText).textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.lightBackground).bgColor,
                ];
            case "slate":
            default:
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
        }
    };

    return (
        <span className={twMerge("inline-flex items-center rounded-full font-thin", getSize(), getColor(), className)} {...restProps}>
            {children}
        </span>
    );
};
