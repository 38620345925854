import { types as commonTypes } from "@app/common";
import { queryClient } from "@app/services";
import { useMutation, useQuery } from "react-query";
import { domainTypes } from "@app/domain";
import { QueryKeys } from "./constants/QueryKeys";
import { getUsers, getUser, updateUser, createUser, deleteUser, getUserDomains } from "./queries";
import { IUser } from "./types";

export const useGetUsers = (domain: domainTypes.IDomain) => useQuery<IUser[], Error>([QueryKeys.UserItems, domain.id], () => getUsers(domain));

export const useGetUserDomains = (userId: number) =>
    useQuery<domainTypes.IDomain[], Error>([QueryKeys.UserDomainItems, userId], () => getUserDomains(userId));

export const useUpdateDomainOrderStatus = (userId: number, domainId: number, newOrderCount: number) => {
    queryClient.setQueryData([QueryKeys.UserDomainItems, userId], (oldDomains: any) => {
        const replacementIndex = oldDomains.findIndex((domain: domainTypes.IDomain) => domain.id === domainId);
        oldDomains[replacementIndex].new_order_count = newOrderCount;

        return oldDomains;
    });
};

export const useGetUser = (userId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IUser, Error>([QueryKeys.UserItem, userId], () => getUser(userId), options);

export const useCreateUser = () =>
    useMutation(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.UserItems);
        },
    });

export const useUpdateUser = () =>
    useMutation(updateUser, {
        onSuccess: (updatedItem: IUser, postedItem) => {
            queryClient.invalidateQueries([QueryKeys.UserItem, postedItem.id]);
        },
    });

export const useDeleteUser = () => useMutation(deleteUser);
