import { pages as authPages } from "@app/auth";
import { Navigate, Route, Routes } from "react-router-dom";

export function UnauthenticatedRoutes() {
    return (
        <Routes>
            <Route path="/auth">
                <Route path="login" element={<authPages.LoginPage />} />
                <Route path="forgot-password" element={<authPages.ForgotPasswordPage />} />
                <Route path="reset-password/:token" element={<authPages.ResetPasswordPage />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/auth/login" />} />
        </Routes>
    );
}
