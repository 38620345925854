/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {IAdapterContract} from "../IAdapterContract";
import { Level } from "../Level";

export interface IConfig {
    adapters: IAdapterContract[];
}

/**
 * Chain adapter
 */
export class Chain implements IAdapterContract {
    protected adapters: IConfig["adapters"];

    constructor(config: IConfig) {
        this.adapters = config.adapters;
    }

    public log(msgs: any[], level: Level): boolean {
        let isSuccess = true;
        this.adapters.forEach((adapter) => {
            isSuccess = isSuccess && adapter.log(msgs, level);
        });

        return isSuccess;
    }
}
