/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {IAdapterContract} from "./IAdapterContract";
import { Level } from "./Level";

/**
 * Log config definition
 */
export interface IConfig {
    [key: string]: any;
    level?: Level;
    adapter: IAdapterContract;
    doAppendLogId?: boolean;
    contextDecorator?: ContextDecorator;
}

/**
 * Context data definition
 */
export interface IContext {
    [key: string]: any;
}

/**
 * ContextDecorator defintion
 */
export type ContextDecorator = (context: IContext) => IContext;

export class Log {
    private level: Level = Level.All;

    private adapter: IAdapterContract;

    private doAppendLogId: boolean;

    constructor(config: IConfig) {
        this.adapter = config.adapter;
        this.doAppendLogId = config.doAppendLogId !== false;

        if (config.level !== undefined) {
            this.level = config.level;
        }

        if (config.contextDecorator) {
            this.contextDecorator = config.contextDecorator;
        }
    }

    /**
     * Public logging methods
     */

    public emergency(...msgs: any[]) {
        return this.log(Level.Emergency, msgs);
    }

    public alert(...msgs: any[]) {
        return this.log(Level.Alert, msgs);
    }

    public critical(...msgs: any[]) {
        return this.log(Level.Critical, msgs);
    }

    public error(...msgs: any[]) {
        return this.log(Level.Error, msgs);
    }

    public warning(...msgs: any[]) {
        return this.log(Level.Warning, msgs);
    }

    public warn(...msgs: any[]) {
        return this.warning(...msgs);
    }

    public notice(...msgs: any[]) {
        return this.log(Level.Notice, msgs);
    }

    public info(...msgs: any[]) {
        return this.log(Level.Info, msgs);
    }

    public debug(...msgs: any[]) {
        return this.log(Level.Debug, msgs);
    }

    private contextDecorator: ContextDecorator = (context) => context;

    private log(msgLevel: Level, msgs: any[]): string | null {
        if (this.level & msgLevel) {
            const msgId: string = this.genMsgId();
            const context = this.contextDecorator({
                _id: msgId,
            });

            const finalMsgs: any[] = this.prepareMsg(msgs, context);

            this.adapter.log(finalMsgs, msgLevel);

            return msgId;
        }

        return null;
    }

    private genMsgId(): string {
        return String(Date.now() + Math.floor(Math.random() * 9999 + 1));
    }

    private prepareMsg(msgs: any[], context: IContext): any[] {
        msgs.map((msg) => typeof msg !== "string"
                ? msg
                : msg.replace(/{([^{}]*)}/g, (a: any, b: any) => {
                      const replace: string = context[b];
                      return typeof replace === "string" || typeof replace === "number" ? replace : a;
                  }));

        if (this.doAppendLogId) {
            msgs.push(` (ref: ${context._id})`);
        }

        return msgs;
    }
}
