import { ControlledFormField } from "@app/common";
import { Button, Form, Input, Title } from "@ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useResetPassword, useValidateRestPasswordToken } from "../hooks";
import { IRequestPasswordResetProps } from "../queries";

export const ResetPasswordPage = () => {
    const { isLoading, isSuccess, isError, mutate: passwordReset } = useResetPassword();
    const { token } = useParams();
    const { data } = useValidateRestPasswordToken(token as string);
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IRequestPasswordResetProps>();

    const onSubmit = (props: IRequestPasswordResetProps) => {
        console.log("check");
        passwordReset(props);
    };

    useEffect(() => reset({ email: data?.email, reset_token: data?.reset_token }), [data]);

    return (
        <section>
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full p-6 rounded-lg shadow  md:mt-0 sm:max-w-md bg-white sm:p-8">
                    <Title weight="bold" className="mb-3">
                        Change password
                    </Title>
                    <Form onSubmit={handleSubmit(onSubmit)} plain={true}>
                        <ControlledFormField control={control} name="email" component={Input} label="Your e-mail" disabled required />
                        <div className="hidden">
                            <ControlledFormField control={control} name="reset_token" component={Input} disabled type="hidden" required />
                        </div>
                        <ControlledFormField
                            control={control}
                            name="password"
                            component={Input}
                            type="password"
                            label="Password"
                            required
                        />

                        <Button color="purple" variant="primary" type="submit">
                            Reset password
                        </Button>
                    </Form>
                </div>
            </div>
        </section>
    );
};
