import { commonConstraints, commonTypes, commonUtils } from "../common";
import { textContraints } from "../text";
import { TButtonVariant, TSizing } from "./types";

export const iconSizes: { [size: string]: TSizing } = {
    xs: {
        height: commonConstraints.sizing.md.height,
        width: commonConstraints.sizing.md.width,
    },
    sm: {
        height: commonConstraints.sizing.lg.height,
        width: commonConstraints.sizing.lg.width,
    },
    md: {
        height: commonConstraints.sizing.lg.height,
        width: commonConstraints.sizing.lg.width,
    },
    lg: {
        height: commonConstraints.sizing.xl.height,
        width: commonConstraints.sizing.xl.width,
    },
    xl: {
        height: commonConstraints.sizing.xl.height,
        width: commonConstraints.sizing.xl.width,
    },
};

export const getButtonProportions = (variant: TButtonVariant) => {
    if (!(variant === "light")) {
        return {
            xs: {
                paddingLeft: commonConstraints.spacing.md.paddingLeft,
                paddingRight: commonConstraints.spacing.md.paddingRight,
                paddingTop: commonConstraints.spacing.xs.paddingTop,
                paddingBottom: commonConstraints.spacing.xs.paddingBottom,
                fontSize: textContraints.fontSizes.xs,
            },
            sm: {
                paddingLeft: commonConstraints.spacing.twoXl.paddingLeft,
                paddingRight: commonConstraints.spacing.twoXl.paddingRight,
                paddingTop: commonConstraints.spacing.sm.paddingTop,
                paddingBottom: commonConstraints.spacing.sm.paddingBottom,
                fontSize: textContraints.fontSizes.sm,
            },
            md: {
                paddingLeft: commonConstraints.spacing.twoXl.paddingLeft,
                paddingRight: commonConstraints.spacing.twoXl.paddingRight,
                paddingTop: commonConstraints.spacing.sm.paddingTop,
                paddingBottom: commonConstraints.spacing.sm.paddingBottom,
                fontSize: textContraints.fontSizes.base,
            },
            lg: {
                paddingLeft: commonConstraints.spacing.twoXl.paddingLeft,
                paddingRight: commonConstraints.spacing.twoXl.paddingRight,
                paddingTop: commonConstraints.spacing.md.paddingTop,
                paddingBottom: commonConstraints.spacing.md.paddingBottom,
                fontSize: textContraints.fontSizes.lg,
            },
            xl: {
                paddingLeft: commonConstraints.spacing.twoXl.paddingLeft,
                paddingRight: commonConstraints.spacing.twoXl.paddingRight,
                paddingTop: commonConstraints.spacing.lg.paddingTop,
                paddingBottom: commonConstraints.spacing.lg.paddingBottom,
                fontSize: textContraints.fontSizes.xl,
            },
        };
    }

    return {
        xs: {
            paddingLeft: "",
            paddingRight: "",
            paddingTop: "",
            paddingBottom: "",
            fontSize: textContraints.fontSizes.xs,
        },
        sm: {
            paddingLeft: "",
            paddingRight: "",
            paddingTop: "",
            paddingBottom: "",
            fontSize: textContraints.fontSizes.sm,
        },
        md: {
            paddingLeft: "",
            paddingRight: "",
            paddingTop: "",
            paddingBottom: "",
            fontSize: textContraints.fontSizes.base,
        },
        lg: {
            paddingLeft: "",
            paddingRight: "",
            paddingTop: "",
            paddingBottom: "",
            fontSize: textContraints.fontSizes.lg,
        },
        xl: {
            paddingLeft: "",
            paddingRight: "",
            paddingTop: "",
            paddingBottom: "",
            fontSize: textContraints.fontSizes.xl,
        },
    };
};

export const getButtonColors = (variant: TButtonVariant, color: commonTypes.TColor) => {
    switch (variant) {
        case "primary":
            return {
                textColor: commonUtils.getColorClassNames("white").textColor,
                hoverTextColor: commonUtils.getColorClassNames("white").textColor,
                bgColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkestBackground).bgColor,
                borderColor: commonUtils.getColorClassNames("transparent").borderColor,
                hoverBorderColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkBackground).hoverBorderColor,
                focusRingColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.ring).focusRingColor,
            };
        case "secondary":
            return {
                textColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.text).textColor,
                hoverTextColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkText).textColor,
                bgColor: commonUtils.getColorClassNames("white").bgColor,
                hoverBgColor: commonUtils.getColorClassNames("white").hoverBgColor,
                borderColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.lightBorder).borderColor,
                hoverBorderColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.border).hoverBorderColor,
                focusRingColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.ring).focusRingColor,
            };
        case "light":
            return {
                textColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.text).textColor,
                hoverTextColor: commonUtils.getColorClassNames(color, commonConstraints.colorPalette.darkText).hoverTextColor,
                bgColor: commonUtils.getColorClassNames("transparent").bgColor,
                hoverBgColor: commonUtils.getColorClassNames("transparent").hoverBgColor,
                borderColor: "",
                hoverBorderColor: "",
                focusRingColor: "",
            };
    }
};
