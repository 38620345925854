import { domainTypes } from "@app/domain";
import { axios } from "@app/services";
import { IUser } from "./types";

export const getUsers = async (domain: domainTypes.IDomain): Promise<IUser[]> => {
    const response = await axios.get<{data: IUser[]}>(`domains/${domain.id}/users`);

    return response.data.data;
};

export const getUser = async (userId: number): Promise<IUser> => {
    const response = await axios.get<IUser>(`users/${userId}`);

    return response.data;
};

export const getUserDomains = async (userId: number): Promise<domainTypes.IDomain[]> => {
    const response = await axios.get<{data: domainTypes.IDomain[]}>(`users/${userId}/domains`);

    return response.data.data;
};

export const createUser = async (user: IUser): Promise<IUser> => {
    const response = await axios.post("domains/1/users", user);

    return response.data;
};

export const updateUser = async (user: IUser): Promise<IUser> => {
    const response = await axios.put<IUser>(`users/${user.id}`, user);

    return response.data;
};

export const deleteUser = async (user: IUser): Promise<boolean> => {
    const response = await axios.delete<boolean>(`users/${user.id}`);

    return response.data;
};
