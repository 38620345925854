import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ContentWithNavigationSidebarLayout } from "./layouts/ContentWithNavigationSidebarLayout";

const DashboardPage = lazy(() => import("@app/dashboard/pages/DashboardPage").then((module) => ({ default: module.DashboardPage })));

const ProductOverviewPage = lazy(() =>
    import("@app/product/pages/ProductOverviewPage").then((module) => ({ default: module.ProductOverviewPage }))
);
const ProductItemPage = lazy(() => import("@app/product/pages/ProductItemPage").then((module) => ({ default: module.ProductItemPage })));

const OrderOverviewPage = lazy(() =>
    import("@app/order/pages/OrderOverviewPage").then((module) => ({ default: module.OrderOverviewPage }))
);
const OrderItemPage = lazy(() => import("@app/order/pages/OrderItemPage").then((module) => ({ default: module.OrderItemPage })));
const OrderCreateItemPage = lazy(() =>
    import("@app/order/pages/OrderCreateItemPage").then((module) => ({ default: module.OrderCreateItemPage }))
);

const PickUpOrderItemConfirmPage = lazy(() =>
    import("@app/order/pages/PickUpOrderItemConfirmPage").then((module) => ({ default: module.PickUpOrderItemConfirmPage }))
);

const ReturnDeliveryOverviewPage = lazy(() =>
    import("@app/returnDelivery/pages/ReturnDeliveryOverviewPage").then((module) => ({ default: module.ReturnDeliveryOverviewPage }))
);
const ReturnDeliveryItemPage = lazy(() =>
    import("@app/returnDelivery/pages/ReturnDeliveryItemPage").then((module) => ({ default: module.ReturnDeliveryItemPage }))
);
const ReturnDeliveryCreateItemPage = lazy(() =>
    import("@app/returnDelivery/pages/ReturnDeliveryCreateItemPage").then((module) => ({ default: module.ReturnDeliveryCreateItemPage }))
);

const UserOverviewPage = lazy(() => import("@app/user/pages/UserOverviewPage").then((module) => ({ default: module.UserOverviewPage })));
const UserItemPage = lazy(() => import("@app/user/pages/UserItemPage").then((module) => ({ default: module.UserItemPage })));

const ChannelOverviewPage = lazy(() =>
    import("@app/channel/pages/ChannelOverviewPage").then((module) => ({ default: module.ChannelOverviewPage }))
);
const ChannelItemPage = lazy(() => import("@app/channel/pages/ChannelItemPage").then((module) => ({ default: module.ChannelItemPage })));

const WarehouseOverviewPage = lazy(() =>
    import("@app/warehouse/pages/WarehouseOverviewPage").then((module) => ({ default: module.WarehouseOverviewPage }))
);
const WarehouseItemPage = lazy(() =>
    import("@app/warehouse/pages/WarehouseItemPage").then((module) => ({ default: module.WarehouseItemPage }))
);

const MailTemplateOverviewPage = lazy(() =>
    import("@app/mailTemplate/pages/MailTemplateOverviewPage").then((module) => ({ default: module.MailTemplateOverviewPage }))
);
const MailTemplateItemPage = lazy(() =>
    import("@app/mailTemplate/pages/MailTemplateItemPage").then((module) => ({ default: module.MailTemplateItemPage }))
);

const QueueOverviewPage = lazy(() =>
    import("@app/queue/pages/QueueOverviewPage").then((module) => ({ default: module.QueueOverviewPage }))
);

const CarrierOverviewPage = lazy(() =>
    import("@app/carrier/pages/CarrierOverviewPage").then((module) => ({ default: module.CarrierOverviewPage }))
);

const CarrierItemPage = lazy(() => import("@app/carrier/pages/CarrierItemPage").then((module) => ({ default: module.CarrierItemPage })));

export function AuthenticatedRoutes() {
    return (
        <Routes>
            <Route path="/" element={<ContentWithNavigationSidebarLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path="/products" element={<ProductOverviewPage />} />
                <Route path="/products/:id" element={<ProductItemPage />} />
                <Route path="/products/new" element={<ProductItemPage />} />
                <Route path="/orders" element={<OrderOverviewPage />} />
                <Route path="/orders/:id" element={<OrderItemPage />} />
                <Route path="/orders/:id/confirm" element={<PickUpOrderItemConfirmPage />} />
                <Route path="/orders/new" element={<OrderCreateItemPage />} />
                <Route path="/orders/new" element={<OrderCreateItemPage />} />
                <Route path="/return-deliveries" element={<ReturnDeliveryOverviewPage />} />
                <Route path="/return-deliveries/:id" element={<ReturnDeliveryItemPage />} />
                <Route path="/return-deliveries/new" element={<ReturnDeliveryCreateItemPage />} />
                <Route path="/users" element={<UserOverviewPage />} />
                <Route path="/users/:id" element={<UserItemPage />} />
                <Route path="/users/new" element={<UserItemPage />} />
                <Route path="/channels" element={<ChannelOverviewPage />} />
                <Route path="/channels/:id" element={<ChannelItemPage />} />
                <Route path="/channels/new" element={<ChannelItemPage />} />
                <Route path="/warehouses" element={<WarehouseOverviewPage />} />
                <Route path="/warehouses/:id" element={<WarehouseItemPage />} />
                <Route path="/mail-templates" element={<MailTemplateOverviewPage />} />
                <Route path="/mail-templates/:id" element={<MailTemplateItemPage />} />
                <Route path="/mail-templates/new" element={<MailTemplateItemPage />} />
                <Route path="/queues" element={<QueueOverviewPage />} />
                <Route path="/carriers" element={<CarrierOverviewPage />} />
                <Route path="/carriers/:id" element={<CarrierItemPage />} />
            </Route>
            <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
    );
}
