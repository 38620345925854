export enum Level {
    None = 0,
    Emergency = 1 << 0,
    Alert = 1 << 1,
    Critical = 1 << 2,
    Error = 1 << 3,
    Warning = 1 << 4,
    Notice = 1 << 5,
    Info = 1 << 6,
    Debug = 1 << 7,
    All = Emergency | Alert | Critical | Error | Warning | Notice | Info | Debug,
}
