import { laravelEcho } from "@app/services";
import { Flex, LoadingMask } from "@ui";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { Sidebar } from "../Sidebar";

export const ContentWithNavigationSidebarLayout = () => {
    const { t } = useTranslation("app");
    const [isNewDeployment, setIsNewDeployment] = useState<boolean>(false);

    useEffect(() => {
        laravelEcho.channel("app").listen(".BroadcastPublicNotificationEvent", () => setIsNewDeployment(true));
    });

    return (
        <React.Fragment>
            <Transition
                show={isNewDeployment}
                enter="transform transition ease-linear duration-700"
                enterFrom="h-0"
                enterTo="h-10 bg-gray-800 fixed z-50 w-full"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Flex height="h-full" justifyContent="justify-center" alignItems="items-center">
                    <p className="text-white text-sm font-light">{t("new-deployment-tigger")}</p>
                </Flex>
            </Transition>
            <Sidebar isNewDeployment={isNewDeployment} />
            <div className="md:pl-20 bg-gray-50 h-full min-h-screen pb-32 pt-10">
                <div className="mx-auto flex flex-col px-4 md:px-12 xl:px-12 h-full">
                    <React.Suspense fallback={<LoadingMask isLoading={true} />}>
                        <Outlet />
                    </React.Suspense>
                </div>
            </div>
        </React.Fragment>
    );
};
