import { colorPalette } from "@ui/common/constraints";
import React, { HTMLAttributes } from "react";
import { twMerge } from "@app/common";
import { commonUtils } from "../common";
import { fontSizes, fontWeights, textAlignments } from "./constraints";
import { IBaseText } from "./types";

interface ITextProps extends IBaseText, Omit<HTMLAttributes<HTMLParagraphElement>, "color"> {}

export const Text = ({
    children,
    size = "sm",
    color = "gray",
    alignment = "left",
    weight = "base",
    truncate,
    className,
    ...restProps
}: ITextProps) => (
    <p
        className={twMerge(
            "mt-0",
            truncate ? "truncate" : "shrink-0",
            commonUtils.getColorClassNames(color, colorPalette.lig).textColor,
            fontSizes[size],
            textAlignments[alignment],
            fontWeights[weight],
            className
        )}
        {...restProps}>
        {children}
    </p>
);
