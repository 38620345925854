/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { IAdapterContract } from "../IAdapterContract";
import { Level } from "../Level";

const levelToMethodMapping = {
    [Level.Emergency]: "error",
    [Level.Alert]: "error",
    [Level.Critical]: "error",
    [Level.Error]: "error",
    [Level.Warning]: "warn",
    [Level.Notice]: "warn",
    [Level.Info]: "info",
    [Level.Debug]: "log",
};

export interface IConsoleContract {
    [key: string]: any;
    log: Function;
    warn: Function;
    error: Function;
    info: Function;
}

export interface IConfig {
    [key: string]: any;
    console: IConsoleContract;
    logPrefix?: string;
}

/**
 * Console adapter
 */
export class Console implements IAdapterContract {
    // protected isConsoleAvailable: boolean;

    protected console: IConsoleContract;

    protected logPrefix: IConfig["logPrefix"];

    constructor(config: IConfig) {
        this.console = config.console;
        this.logPrefix = config.logPrefix || "";
    }

    public log(msgs: any[], level: Level): boolean {
        if (this.console && level in levelToMethodMapping) {
            this.console[levelToMethodMapping[level]](this.logPrefix, ...msgs);

            // DEBUG: add location
            // this.console[levelToMethodMapping[level]](
            //     `[HC]`,
            //     ...msgs,
            //     " @",
            //     Error()
            //         .stack.split(/\n/g)[4]
            //         .replace("webpack-internal:///", "")
            // );
            return true;
        }

        return false;
    }

    private resolveConsoleReference() {}
}
