import { useForm } from "react-hook-form";
import { Button, LoadingMask } from "../../common/componentLibrary";
import { useForgotPassword } from "../hooks";
import { IRequestPasswordResetProps } from "../queries";

export function ForgotPasswordPage() {
    const { isLoading, isSuccess, isError, mutate: requestForgotPassword } = useForgotPassword();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IRequestPasswordResetProps>();
    const formHasErrors = !!Object.keys(errors).length;
    const showForm = !isLoading && !isSuccess && !isError;

    function onSubmit(props: IRequestPasswordResetProps) {
        requestForgotPassword(props);
    }

    return (
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md relative">
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {isError && <p className="my-4 text-xl font-normal text-center">Somehting went wrong! Please try again later</p>}

                    {isSuccess && (
                        <p className="my-4 text-xl font-normal text-center">
                            We sent you an email if your email address exists in our database.
                        </p>
                    )}

                    <LoadingMask isLoading={isLoading} />

                    {showForm && (
                        <div className="col-span-3 sm:col-span-2">
                            <label htmlFor="company-website" className="block text-center font-bold text-xl text-gray-700 font-heading">
                                Forgot your password?
                                <br /> We'll send you an email with a reset link
                            </label>
                            <div>
                                <div className="mt-4">
                                    <input
                                        {...register("email")}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="mt-6">
                                <Button disabled={formHasErrors} type="submit">
                                    Reset password
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}
