import { FormField, IFormFieldProps, TFormFieldBaseComponent } from "@ui";
import React from "react";
import { FieldValues, Path, useController, UseControllerProps } from "react-hook-form";

export interface IControllerFormFieldProps<TComponent, TFieldValues extends FieldValues>
    extends Omit<IFormFieldProps<TComponent>, "onChange" | "name">,
        UseControllerProps<TFieldValues, Path<TFieldValues>> {}

export const ControlledFormField = <TComponent extends TFormFieldBaseComponent, TFieldValues extends FieldValues>({
    control,
    name,
    rules,
    defaultValue,
    ...formFieldProps
}: IControllerFormFieldProps<TComponent, TFieldValues> & Parameters<TComponent>[0]) => {
    const {
        field: { onChange, onBlur, value = defaultValue || "", ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { touchedFields, dirtyFields },
    } = useController<TFieldValues>({
        name,
        control,
        rules,
        defaultValue,
    });

    return <FormField error={error?.message} {...formFieldProps} name={name} onChange={onChange} onBlur={onBlur} value={value} ref={ref} />;
};
