import { TButtonVariant, THorizontalPosition } from "./types";

export const ButtonVariants: { [key: string]: TButtonVariant } = {
    Primary: "primary",
    Secondary: "secondary",
    Light: "light",
};

export const HorizontalPositions: { [key: string]: THorizontalPosition } = {
    Left: "left",
    Right: "right",
};
