import React, { HTMLAttributes } from "react";
import { Transition } from "react-transition-group";
import { textContraints } from "@ui/text";
import { twMerge } from "@app/common/utils/twMerge";
import { commonConstraints, commonTypes } from "../common";
import { LoadingSpinner } from "./LoadingSpinner";
import { HorizontalPositions } from "./constraints";
import { TButtonType, TButtonVariant, THorizontalPosition } from "./types";
import { getButtonColors, getButtonProportions } from "./utils";

export interface ButtonIconOrSpinnerProps {
    loading: boolean;
    iconSize: string;
    iconPosition: string;
    Icon?: React.ElementType | undefined;
    transitionState: string;
}

export const ButtonIconOrSpinner = ({ loading, iconSize, iconPosition, Icon, transitionState }: ButtonIconOrSpinnerProps) => {
    const margin =
        iconPosition === HorizontalPositions.Left
            ? twMerge(commonConstraints.spacing.twoXs.negativeMarginLeft, commonConstraints.spacing.xs.marginRight)
            : twMerge(commonConstraints.spacing.twoXs.negativeMarginRight, commonConstraints.spacing.xs.marginLeft);

    const defaultSpinnerSize = twMerge(commonConstraints.sizing.none.width, commonConstraints.sizing.none.height);
    const spinnerSize: { [key: string]: any } = {
        default: defaultSpinnerSize,
        entering: defaultSpinnerSize,
        entered: iconSize,
        exiting: iconSize,
        exited: defaultSpinnerSize,
    };

    return loading ? (
        <LoadingSpinner
            className={twMerge("animate-spin w-8", margin, spinnerSize.default, spinnerSize[transitionState])}
            style={{ transition: "width 150ms" }}
        />
    ) : Icon ? (
        <Icon className={twMerge(iconSize, margin)} aria-hidden="true" />
    ) : (
        <span />
    );
};

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
    type?: TButtonType;
    text?: string;
    value?: any;
    icon?: React.ElementType;
    iconPosition?: THorizontalPosition;
    size?: commonTypes.TSizes;
    color?: Exclude<commonTypes.TColor, "blue" | "gray" | "yellow" | "blue" | "green">;
    variant?: TButtonVariant;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onSubmit?: React.FormEventHandler<HTMLButtonElement>;
    onReset?: React.FormEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
    children?: React.ReactNode;
}

export const Button = ({
    type = "button",
    text,
    value,
    icon,
    iconPosition = HorizontalPositions.Left,
    onClick,
    onSubmit,
    onReset,
    size = "sm",
    color = "slate",
    variant = "secondary",
    disabled = false,
    loading = false,
    loadingText = "Saving...",
    children,
    className,
    ...restProps
}: IButtonProps) => {
    const Icon = icon;
    const buttonVariant = variant;
    const isDisabled = loading || disabled;
    const showButtonIconOrSpinner = Icon !== undefined || loading;
    const showLoadingText = loading && loadingText;
    const iconSize = twMerge(commonConstraints.iconSizes[size].height, commonConstraints.iconSizes[size].width);
    const buttonShapeStyles = variant !== "light" ? "shadow-sm rounded-md border" : "";
    const buttonColorStyles = getButtonColors(buttonVariant, color ?? commonConstraints.BaseColors.Purple);
    const buttonProportionStyles = getButtonProportions(buttonVariant)[size];

    return (
        <Transition in={loading} timeout={50}>
            {(state) => (
                <button
                    type={type}
                    value={value}
                    onClick={onClick}
                    onSubmit={onSubmit}
                    onReset={onReset}
                    className={twMerge(
                        "flex-shrink-0 inline-flex items-center group",
                        "focus:outline-none focus:ring-2 focus:ring-offset-2",
                        "focus:ring-transparent",
                        textContraints.fontWeights.base,
                        buttonShapeStyles,
                        buttonProportionStyles.paddingLeft,
                        buttonProportionStyles.paddingRight,
                        buttonProportionStyles.paddingTop,
                        buttonProportionStyles.paddingBottom,
                        buttonProportionStyles.fontSize,
                        buttonColorStyles.textColor,
                        buttonColorStyles.bgColor,
                        buttonColorStyles.borderColor,
                        buttonColorStyles.focusRingColor,
                        !isDisabled
                            ? twMerge(
                                  getButtonColors(buttonVariant, color).hoverTextColor,
                                  getButtonColors(buttonVariant, color).hoverBgColor,
                                  getButtonColors(buttonVariant, color).hoverBorderColor
                              )
                            : "opacity-50",
                        className
                    )}
                    disabled={isDisabled}
                    {...restProps}>
                    {showButtonIconOrSpinner && iconPosition !== HorizontalPositions.Right && (
                        <ButtonIconOrSpinner
                            loading={loading}
                            iconSize={iconSize}
                            iconPosition={iconPosition}
                            Icon={Icon}
                            transitionState={state}
                        />
                    )}

                    {<p className="whitespace-nowrap">{showLoadingText ? loadingText : !children ? text : children}</p>}
                    {showButtonIconOrSpinner && iconPosition === HorizontalPositions.Right && (
                        <ButtonIconOrSpinner
                            loading={loading}
                            iconSize={iconSize}
                            iconPosition={iconPosition}
                            Icon={Icon}
                            transitionState={state}
                        />
                    )}
                </button>
            )}
        </Transition>
    );
};
