import { UtilsNumeric } from "../../../hotelchamp";

export type TFormFieldTransform = "Number" | "String" | ((extractedValue: any, rawValue: any) => any) | undefined;

export const handleFormFieldTransform = (transform: TFormFieldTransform, rawValue: any) => {
    const extractedValue = extractFormFieldOnChangeValue(rawValue);

    switch (transform) {
        case "Number":
            return UtilsNumeric.isNumeric(extractedValue) ? Number(extractedValue) : extractedValue;
        case "String":
            return String(extractedValue);
        default:
            if (typeof transform === "function") {
                return transform(extractedValue, rawValue);
            }
    }

    return rawValue;
};

/**
 * extractOnChangeValue
 * Helper method to extract the 'real' value from an onChange event.
 * onChange event could be triggered by native html form element or by custom form
 * element. When native, the value will be an event. Try to extract value from event.target.value or event.target.checked.
 * When non event value, just take first param as value
 * @param value
 * @returns
 */
export const extractFormFieldOnChangeValue = (value: any) => {
    if (value) {
        if (typeof value === "object" && "target" in value) {
            const target = value.target;

            if (["radio", "checkbox"].includes(target.type.toLowerCase())) {
                return target.checked;
            } else {
                return target.value;
            }
        }
    }

    return value;
};
