import { IBaseColorTheme, IColorTypes, TColor } from "./types";
import { commonTypes } from ".";

export const sizeValues = ["xs", "sm", "md", "lg", "xl"] as const;

export const justifyContentValues = [
    "justify-start",
    "justify-end",
    "justify-center",
    "justify-between",
    "justify-around",
    "justify-evenly",
] as const;

export const alignItemValues = ["items-start", "items-end", "items-center", "items-baseline", "items-stretch"] as const;

export const marginTopValues = [
    "mt-0",
    "mt-0.5",
    "mt-1",
    "mt-1.5",
    "mt-2",
    "mt-2.5",
    "mt-3",
    "mt-3.5",
    "mt-4",
    "mt-5",
    "mt-6",
    "mt-7",
    "mt-8",
    "mt-9",
    "mt-10",
    "mt-11",
    "mt-12",
    "mt-14",
    "mt-16",
    "mt-20",
    "mt-24",
    "mt-28",
    "mt-32",
    "mt-36",
    "mt-40",
    "mt-44",
    "mt-48",
    "mt-52",
    "mt-56",
    "mt-60",
    "mt-64",
    "mt-72",
    "mt-80",
    "mt-96",
    "-mt-0.5",
    "-mt-1",
    "-mt-1.5",
    "-mt-2",
    "-mt-2.5",
    "-mt-3",
    "-mt-3.5",
    "-mt-4",
    "-mt-5",
    "-mt-6",
    "-mt-7",
    "-mt-8",
    "-mt-9",
    "-mt-10",
    "-mt-11",
    "-mt-12",
    "-mt-14",
    "-mt-16",
    "-mt-20",
    "-mt-24",
    "-mt-28",
    "-mt-32",
    "-mt-36",
    "-mt-40",
    "-mt-44",
    "-mt-48",
    "-mt-52",
    "-mt-56",
    "-mt-60",
    "-mt-64",
    "-mt-72",
    "-mt-80",
    "-mt-96",
] as const;

export const spaceXValues = [
    "space-x-0",
    "space-x-0.5",
    "space-x-1",
    "space-x-1.5",
    "space-x-2",
    "space-x-2.5",
    "space-x-3",
    "space-x-3.5",
    "space-x-4",
    "space-x-5",
    "space-x-6",
    "space-x-7",
    "space-x-8",
    "space-x-9",
    "space-x-10",
    "space-x-11",
    "space-x-12",
    "space-x-14",
    "space-x-16",
    "space-x-20",
    "space-x-24",
    "space-x-28",
    "space-x-32",
    "space-x-36",
    "space-x-40",
    "space-x-44",
    "space-x-48",
    "space-x-52",
    "space-x-56",
    "space-x-60",
    "space-x-64",
    "space-x-72",
    "space-x-80",
    "space-x-96",
    "-space-x-0.5",
    "-space-x-1",
    "-space-x-1.5",
    "-space-x-2",
    "-space-x-2.5",
    "-space-x-3",
    "-space-x-3.5",
    "-space-x-4",
    "-space-x-5",
    "-space-x-6",
    "-space-x-7",
    "-space-x-8",
    "-space-x-9",
    "-space-x-10",
    "-space-x-11",
    "-space-x-12",
    "-space-x-14",
    "-space-x-16",
    "-space-x-20",
    "-space-x-24",
    "-space-x-28",
    "-space-x-32",
    "-space-x-36",
    "-space-x-40",
    "-space-x-44",
    "-space-x-48",
    "-space-x-52",
    "-space-x-56",
    "-space-x-60",
    "-space-x-64",
    "-space-x-72",
    "-space-x-80",
    "-space-x-96",
] as const;

export const spaceYValues = [
    "space-y-0",
    "space-y-0.5",
    "space-y-1",
    "space-y-1.5",
    "space-y-2",
    "space-y-2.5",
    "space-y-3",
    "space-y-3.5",
    "space-y-4",
    "space-y-5",
    "space-y-6",
    "space-y-7",
    "space-y-8",
    "space-y-9",
    "space-y-10",
    "space-y-11",
    "space-y-12",
    "space-y-14",
    "space-y-16",
    "space-y-20",
    "space-y-24",
    "space-y-28",
    "space-y-32",
    "space-y-36",
    "space-y-40",
    "space-y-44",
    "space-y-48",
    "space-y-52",
    "space-y-56",
    "space-y-60",
    "space-y-64",
    "space-y-72",
    "space-y-80",
    "space-y-96",
    "-space-y-0.5",
    "-space-y-1",
    "-space-y-1.5",
    "-space-y-2",
    "-space-y-2.5",
    "-space-y-3",
    "-space-y-3.5",
    "-space-y-4",
    "-space-y-5",
    "-space-y-6",
    "-space-y-7",
    "-space-y-8",
    "-space-y-9",
    "-space-y-10",
    "-space-y-11",
    "-space-y-12",
    "-space-y-14",
    "-space-y-16",
    "-space-y-20",
    "-space-y-24",
    "-space-y-28",
    "-space-y-32",
    "-space-y-36",
    "-space-y-40",
    "-space-y-44",
    "-space-y-48",
    "-space-y-52",
    "-space-y-56",
    "-space-y-60",
    "-space-y-64",
    "-space-y-72",
    "-space-y-80",
    "-space-y-96",
] as const;

export const gapXValues = [
    "gap-x-0",
    "gap-x-0.5",
    "gap-x-1",
    "gap-x-1.5",
    "gap-x-2",
    "gap-x-2.5",
    "gap-x-3",
    "gap-x-3.5",
    "gap-x-4",
    "gap-x-5",
    "gap-x-6",
    "gap-x-7",
    "gap-x-8",
    "gap-x-9",
    "gap-x-10",
    "gap-x-11",
    "gap-x-12",
    "gap-x-14",
    "gap-x-16",
    "gap-x-20",
    "gap-x-24",
    "gap-x-28",
    "gap-x-32",
    "gap-x-36",
    "gap-x-40",
    "gap-x-44",
    "gap-x-48",
    "gap-x-52",
    "gap-x-56",
    "gap-x-60",
    "gap-x-64",
    "gap-x-72",
    "gap-x-80",
    "gap-x-96",
] as const;

export const gapYValues = [
    "gap-y-0",
    "gap-y-0.5",
    "gap-y-1",
    "gap-y-1.5",
    "gap-y-2",
    "gap-y-2.5",
    "gap-y-3",
    "gap-y-3.5",
    "gap-y-4",
    "gap-y-5",
    "gap-y-6",
    "gap-y-7",
    "gap-y-8",
    "gap-y-9",
    "gap-y-10",
    "gap-y-11",
    "gap-y-12",
    "gap-y-14",
    "gap-y-16",
    "gap-y-20",
    "gap-y-24",
    "gap-y-28",
    "gap-y-32",
    "gap-y-36",
    "gap-y-40",
    "gap-y-44",
    "gap-y-48",
    "gap-y-52",
    "gap-y-56",
    "gap-y-60",
    "gap-y-64",
    "gap-y-72",
    "gap-y-80",
    "gap-y-96",
] as const;

export const baseColorValues = [
    "green",
    "blue",
    "yellow",
    "gray",
    "red",
    "slate",
    "purple",
    "indigo",
    "violet",
    "sky",
    "cyan",
    "fuchsia",
] as const;

export const BaseColors: { [key: string]: TColor } = {
    Slate: "slate",
    Gray: "gray",
    Red: "red",
    Yellow: "yellow",
    Green: "green",
    Blue: "blue",
    Purple: "purple",
    Indigo: "indigo",
    Violet: "violet",
    Sky: "sky",
    Cyan: "cyan",
    Fuchsia: "fuchsia",
} as const;

export const colorTheme: { [baseColor: string]: IBaseColorTheme } = {
    [BaseColors.Green]: {
        canvasBackground: "green-50",
        lightBackground: "green-100",
        background: "green-500",
        darkBackground: "green-600",
        darkestBackground: "green-800",
        lightBorder: "green-200",
        border: "green-400",
        darkBorder: "green-600",
        lightRing: "green-200",
        ring: "green-400",
        lightText: "green-400",
        text: "green-500",
        darkText: "green-600",
        icon: "green-500",
    },
    [BaseColors.Yellow]: {
        canvasBackground: "yellow-50",
        lightBackground: "yellow-300",
        background: "yellow-500",
        darkBackground: "yellow-600",
        darkestBackground: "yellow-800",
        lightBorder: "yellow-200",
        border: "yellow-400",
        darkBorder: "yellow-600",
        lightRing: "yellow-200",
        ring: "yellow-400",
        lightText: "yellow-400",
        text: "yellow-500",
        darkText: "yellow-700",
        icon: "yellow-500",
    },
    [BaseColors.Blue]: {
        canvasBackground: "blue-50",
        lightBackground: "blue-200",
        background: "blue-700",
        darkBackground: "blue-600",
        darkestBackground: "blue-800",
        lightBorder: "blue-200",
        border: "blue-400",
        darkBorder: "blue-600",
        lightRing: "blue-200",
        ring: "blue-400",
        lightText: "blue-400",
        text: "blue-500",
        darkText: "blue-700",
        icon: "blue-500",
    },
    [BaseColors.Slate]: {
        canvasBackground: "slate-50",
        lightBackground: "slate-100",
        background: "slate-400",
        darkBackground: "slate-600",
        darkestBackground: "slate-800",
        lightBorder: "slate-300",
        border: "slate-400",
        darkBorder: "slate-600",
        lightRing: "slate-200",
        ring: "slate-400",
        lightText: "slate-400",
        text: "slate-700",
        darkText: "slate-700",
        icon: "slate-500",
    },
    [BaseColors.Gray]: {
        canvasBackground: "gray-50",
        lightBackground: "gray-100",
        background: "gray-500",
        darkBackground: "gray-600",
        darkestBackground: "gray-800",
        lightBorder: "gray-200",
        border: "gray-400",
        darkBorder: "gray-600",
        lightRing: "gray-200",
        ring: "gray-400",
        lightText: "gray-500",
        text: "gray-700",
        darkText: "gray-900",
        icon: "gray-500",
    },
    [BaseColors.Red]: {
        canvasBackground: "red-50",
        lightBackground: "red-100",
        background: "red-500",
        darkBackground: "red-600",
        darkestBackground: "red-800",
        lightBorder: "red-200",
        border: "red-400",
        darkBorder: "red-600",
        lightRing: "red-200",
        ring: "red-400",
        lightText: "red-400",
        text: "red-400",
        darkText: "red-800",
        icon: "red-500",
    },
    [BaseColors.Purple]: {
        canvasBackground: "purple-50",
        lightBackground: "purple-100",
        background: "purple-800",
        darkBackground: "purple-900", // hover
        darkestBackground: "purple-900", // selected
        lightBorder: "purple-200",
        border: "purple-400",
        darkBorder: "purple-600",
        lightRing: "purple-200",
        ring: "purple-400",
        lightText: "purple-400",
        text: "purple-500",
        darkText: "purple-700",
        icon: "purple-500",
    },
    [BaseColors.Indigo]: {
        canvasBackground: "indigo-50",
        lightBackground: "indigo-100",
        background: "indigo-500",
        darkBackground: "indigo-600", // hover
        darkestBackground: "indigo-800", // selected
        lightBorder: "indigo-200",
        border: "indigo-400",
        darkBorder: "indigo-600",
        lightRing: "indigo-200",
        ring: "indigo-400",
        lightText: "indigo-400",
        text: "indigo-500",
        darkText: "indigo-700",
        icon: "indigo-500",
    },
    [BaseColors.Violet]: {
        canvasBackground: "violet-50",
        lightBackground: "violet-100",
        background: "violet-500",
        darkBackground: "violet-600", // hover
        darkestBackground: "violet-800", // selected
        lightBorder: "violet-200",
        border: "violet-400",
        darkBorder: "violet-600",
        lightRing: "violet-200",
        ring: "violet-400",
        lightText: "violet-400",
        text: "violet-500",
        darkText: "violet-700",
        icon: "violet-500",
    },
    [BaseColors.Sky]: {
        canvasBackground: "sky-50",
        lightBackground: "sky-100",
        background: "sky-500",
        darkBackground: "sky-600", // hover
        darkestBackground: "sky-800", // selected
        lightBorder: "sky-200",
        border: "sky-400",
        darkBorder: "sky-600",
        lightRing: "sky-200",
        ring: "sky-400",
        lightText: "sky-400",
        text: "sky-500",
        darkText: "sky-700",
        icon: "sky-500",
    },
    [BaseColors.Cyan]: {
        canvasBackground: "cyan-50",
        lightBackground: "cyan-100",
        background: "cyan-500",
        darkBackground: "cyan-600", // hover
        darkestBackground: "cyan-800", // selected
        lightBorder: "cyan-200",
        border: "cyan-400",
        darkBorder: "cyan-600",
        lightRing: "cyan-200",
        ring: "cyan-400",
        lightText: "cyan-400",
        text: "cyan-500",
        darkText: "cyan-700",
        icon: "cyan-500",
      },
    [BaseColors.Fuchsia]: {
        canvasBackground: "fuchsia-50",
        lightBackground: "fuchsia-100",
        background: "fuchsia-500",
        darkBackground: "fuchsia-600", // hover
        darkestBackground: "fuchsia-800", // selected
        lightBorder: "fuchsia-200",
        border: "fuchsia-400",
        darkBorder: "fuchsia-600",
        lightRing: "fuchsia-200",
        ring: "fuchsia-400",
        lightText: "fuchsia-400",
        text: "fuchsia-500",
        darkText: "fuchsia-700",
        icon: "fuchsia-500",
    },
} as const;

export const hexColors: { [color: string]: string } = {
    [BaseColors.Slate]: "#64748b",
    [BaseColors.Gray]: "#6b7280",
    [BaseColors.Zinc]: "#71717a",
    [BaseColors.Neutral]: "#737373",
    [BaseColors.Stone]: "#78716c",
    [BaseColors.Red]: "#ef4444",
    [BaseColors.Orange]: "#f97316",
    [BaseColors.Amber]: "#f59e0b",
    [BaseColors.Yellow]: "#eab308",
    [BaseColors.Lime]: "#84cc16",
    [BaseColors.Green]: "#22c55e",
    [BaseColors.Emerald]: "#10b981",
    [BaseColors.Teal]: "#14b8a6",
    [BaseColors.Cyan]: "#06b6d4",
    [BaseColors.Sky]: "#0ea5e9",
    [BaseColors.Blue]: "#3b82f6",
    [BaseColors.Indigo]: "#6366f1",
    [BaseColors.Violet]: "#8b5cf6",
    [BaseColors.Purple]: "#a855f7",
    [BaseColors.Fuchsia]: "#d946ef",
    [BaseColors.Pink]: "#ec4899",
    [BaseColors.Rose]: "#f43f5e",
    [BaseColors.Black]: "#000000",
    [BaseColors.White]: "#ffffff",
  };

export const colorPalette: {[key: string]: number} = {
    canvasBackground: 50,
    lightBackground: 100,
    background: 500,
    darkBackground: 600,
    darkestBackground: 800,
    lightBorder: 200,
    border: 500,
    darkBorder: 700,
    lightRing: 200,
    ring: 300,
    lightText: 400,
    text: 500,
    darkText: 700,
    darkestText: 900,
    icon: 500,
} as const;

// export const defaultColors: IDefaultColors = {
//     transparent: "transparent-none",
//     white: "white-none",
//     black: "black-none",
//     canvasBackground: "gray-50",
//     lightBackground: "gray-100",
//     background: "gray-200",
//     darkBackground: "gray-400",
//     darkestBackground: "gray-600",
//     lightBorder: "gray-200",
//     border: "gray-300",
//     darkBorder: "gray-600",
//     lightRing: "gray-200",
//     ring: "blue-300",
//     lightText: "gray-400",
//     text: "gray-500",
//     darkText: "gray-700",
//     darkestText: "gray-900",
//     icon: "gray-500",
// } as const;

export const themeColorRange: TColor[] = [
    BaseColors.Cyan,
    BaseColors.Sky,
    BaseColors.Indigo,
    BaseColors.Violet,
    BaseColors.Purple,
    BaseColors.Fuchsia,
    BaseColors.Blue,
    BaseColors.Indigo,
    BaseColors.Violet,
    BaseColors.Purple,
    BaseColors.Slate,
    BaseColors.Gray,
    BaseColors.Red,
    BaseColors.Yellow,
    BaseColors.Green,
];

export const colorClassNames: {
    [baseColor: string]: { [colorVariant: string]: IColorTypes };
} = {
    transparent: {
        500: {
          bgColor: "bg-transparent",
          hoverBgColor: "hover:bg-transparent",
          textColor: "text-transparent",
          hoverTextColor: "hover:text-transparent",
          borderColor: "border-transparent",
          hoverBorderColor: "hover:border-transparent",
          ringColor: "ring-transparent",
          hoverRingColor: "hover:ring-transparent",
          divideColor: "divide-transparent",
          outlineColor: "outline-transparent",
          focusRingColor: "focus:ring-transparent",
        },
      },
      white: {
        500: {
          bgColor: "bg-white",
          hoverBgColor: "hover:bg-white",
          textColor: "text-white",
          hoverTextColor: "hover:text-white",
          borderColor: "border-white",
          hoverBorderColor: "hover:border-white",
          ringColor: "ring-white",
          hoverRingColor: "hover:ring-white",
          divideColor: "divide-white",
          outlineColor: "outline-white",
          focusRingColor: "focus:ring-white",
        },
      },
      black: {
        500: {
          bgColor: "bg-black",
          hoverBgColor: "hover:bg-black",
          textColor: "text-black",
          hoverTextColor: "hover:text-black",
          borderColor: "border-black",
          hoverBorderColor: "hover:border-black",
          ringColor: "ring-black",
          hoverRingColor: "hover:ring-black",
          divideColor: "divide-black",
          outlineColor: "outline-black",
          focusRingColor: "focus:ring-black",
        },
      },
    slate: {
        50: {
            bgColor: "bg-slate-50",
            hoverBgColor: "hover:bg-slate-50",
            textColor: "text-slate-50",
            hoverTextColor: "hover:text-slate-50",
            borderColor: "border-slate-50",
            hoverBorderColor: "hover:border-slate-50",
            ringColor: "ring-slate-50",
            hoverRingColor: "hover:ring-slate-50",
            divideColor: "divide-slate-50",
            outlineColor: "outline-slate-50",
            focusRingColor: "focus:ring-slate-50",
        },
        100: {
            bgColor: "bg-slate-100",
            hoverBgColor: "hover:bg-slate-100",
            textColor: "text-slate-100",
            hoverTextColor: "hover:text-slate-100",
            borderColor: "border-slate-100",
            hoverBorderColor: "hover:border-slate-100",
            ringColor: "ring-slate-100",
            hoverRingColor: "hover:ring-slate-100",
            divideColor: "divide-slate-100",
            outlineColor: "outline-slate-100",
            focusRingColor: "focus:ring-slate-100",
        },
        200: {
            bgColor: "bg-slate-200",
            hoverBgColor: "hover:bg-slate-200",
            textColor: "text-slate-200",
            hoverTextColor: "hover:text-slate-200",
            borderColor: "border-slate-200",
            hoverBorderColor: "hover:border-slate-200",
            ringColor: "ring-slate-200",
            hoverRingColor: "hover:ring-slate-200",
            divideColor: "divide-slate-200",
            outlineColor: "outline-slate-200",
            focusRingColor: "focus:ring-slate-200",
        },
        300: {
            bgColor: "bg-slate-300",
            hoverBgColor: "hover:bg-slate-300",
            textColor: "text-slate-300",
            hoverTextColor: "hover:text-slate-300",
            borderColor: "border-slate-300",
            hoverBorderColor: "hover:border-slate-300",
            ringColor: "ring-slate-300",
            hoverRingColor: "hover:ring-slate-300",
            divideColor: "divide-slate-300",
            outlineColor: "outline-slate-300",
            focusRingColor: "focus:ring-slate-300",
        },
        400: {
            bgColor: "bg-slate-400",
            hoverBgColor: "hover:bg-slate-400",
            textColor: "text-slate-400",
            hoverTextColor: "hover:text-slate-400",
            borderColor: "border-slate-400",
            hoverBorderColor: "hover:border-slate-400",
            ringColor: "ring-slate-400",
            hoverRingColor: "hover:ring-slate-400",
            divideColor: "divide-slate-400",
            outlineColor: "outline-slate-400",
            focusRingColor: "focus:ring-slate-400",
        },
        500: {
            bgColor: "bg-slate-500",
            hoverBgColor: "hover:bg-slate-500",
            textColor: "text-slate-500",
            hoverTextColor: "hover:text-slate-500",
            borderColor: "border-slate-500",
            hoverBorderColor: "hover:border-slate-500",
            ringColor: "ring-slate-500",
            hoverRingColor: "hover:ring-slate-500",
            divideColor: "divide-slate-500",
            outlineColor: "outline-slate-500",
            focusRingColor: "focus:ring-slate-500",
        },
        600: {
            bgColor: "bg-slate-600",
            hoverBgColor: "hover:bg-slate-600",
            textColor: "text-slate-600",
            hoverTextColor: "hover:text-slate-600",
            borderColor: "border-slate-600",
            hoverBorderColor: "hover:border-slate-600",
            ringColor: "ring-slate-600",
            hoverRingColor: "hover:ring-slate-600",
            divideColor: "divide-slate-600",
            outlineColor: "outline-slate-600",
            focusRingColor: "focus:ring-slate-600",
        },
        700: {
            bgColor: "bg-slate-700",
            hoverBgColor: "hover:bg-slate-700",
            textColor: "text-slate-700",
            hoverTextColor: "hover:text-slate-700",
            borderColor: "border-slate-700",
            hoverBorderColor: "hover:border-slate-700",
            ringColor: "ring-slate-700",
            hoverRingColor: "hover:ring-slate-700",
            divideColor: "divide-slate-700",
            outlineColor: "outline-slate-700",
            focusRingColor: "focus:ring-slate-700",
        },
        800: {
            bgColor: "bg-slate-800",
            hoverBgColor: "hover:bg-slate-800",
            textColor: "text-slate-800",
            hoverTextColor: "hover:text-slate-800",
            borderColor: "border-slate-800",
            hoverBorderColor: "hover:border-slate-800",
            ringColor: "ring-slate-800",
            hoverRingColor: "hover:ring-slate-800",
            divideColor: "divide-slate-800",
            outlineColor: "outline-slate-800",
            focusRingColor: "focus:ring-slate-800",
        },
        900: {
            bgColor: "bg-slate-900",
            hoverBgColor: "hover:bg-slate-900",
            textColor: "text-slate-900",
            hoverTextColor: "hover:text-slate-900",
            borderColor: "border-slate-900",
            hoverBorderColor: "hover:border-slate-900",
            ringColor: "ring-slate-900",
            hoverRingColor: "hover:ring-slate-900",
            divideColor: "divide-slate-900",
            outlineColor: "outline-slate-900",
            focusRingColor: "focus:ring-slate-900",
        },
    },
    gray: {
        50: {
            bgColor: "bg-gray-50",
            hoverBgColor: "hover:bg-gray-50",
            textColor: "text-gray-50",
            hoverTextColor: "hover:text-gray-50",
            borderColor: "border-gray-50",
            hoverBorderColor: "hover:border-gray-50",
            ringColor: "ring-gray-50",
            hoverRingColor: "hover:ring-gray-50",
            divideColor: "divide-gray-50",
            outlineColor: "outline-gray-50",
            focusRingColor: "focus:ring-gray-50",
        },
        100: {
            bgColor: "bg-gray-100",
            hoverBgColor: "hover:bg-gray-100",
            textColor: "text-gray-100",
            hoverTextColor: "hover:text-gray-100",
            borderColor: "border-gray-100",
            hoverBorderColor: "hover:border-gray-100",
            ringColor: "ring-gray-100",
            hoverRingColor: "hover:ring-gray-100",
            divideColor: "divide-gray-100",
            outlineColor: "outline-gray-100",
            focusRingColor: "focus:ring-gray-100",
        },
        200: {
            bgColor: "bg-gray-200",
            hoverBgColor: "hover:bg-gray-200",
            textColor: "text-gray-200",
            hoverTextColor: "hover:text-gray-200",
            borderColor: "border-gray-200",
            hoverBorderColor: "hover:border-gray-200",
            ringColor: "ring-gray-200",
            hoverRingColor: "hover:ring-gray-200",
            divideColor: "divide-gray-200",
            outlineColor: "outline-gray-200",
            focusRingColor: "focus:ring-gray-200",
        },
        300: {
            bgColor: "bg-gray-300",
            hoverBgColor: "hover:bg-gray-300",
            textColor: "text-gray-300",
            hoverTextColor: "hover:text-gray-300",
            borderColor: "border-gray-300",
            hoverBorderColor: "hover:border-gray-300",
            ringColor: "ring-gray-300",
            hoverRingColor: "hover:ring-gray-300",
            divideColor: "divide-gray-300",
            outlineColor: "outline-gray-300",
            focusRingColor: "focus:ring-gray-300",
        },
        400: {
            bgColor: "bg-gray-400",
            hoverBgColor: "hover:bg-gray-400",
            textColor: "text-gray-400",
            hoverTextColor: "hover:text-gray-400",
            borderColor: "border-gray-400",
            hoverBorderColor: "hover:border-gray-400",
            ringColor: "ring-gray-400",
            hoverRingColor: "hover:ring-gray-400",
            divideColor: "divide-gray-400",
            outlineColor: "outline-gray-400",
            focusRingColor: "focus:ring-gray-400",
        },
        500: {
            bgColor: "bg-gray-500",
            hoverBgColor: "hover:bg-gray-500",
            textColor: "text-gray-500",
            hoverTextColor: "hover:text-gray-500",
            borderColor: "border-gray-500",
            hoverBorderColor: "hover:border-gray-500",
            ringColor: "ring-gray-500",
            hoverRingColor: "hover:ring-gray-500",
            divideColor: "divide-gray-500",
            outlineColor: "outline-gray-500",
            focusRingColor: "focus:ring-gray-500",
        },
        600: {
            bgColor: "bg-gray-600",
            hoverBgColor: "hover:bg-gray-600",
            textColor: "text-gray-600",
            hoverTextColor: "hover:text-gray-600",
            borderColor: "border-gray-600",
            hoverBorderColor: "hover:border-gray-600",
            ringColor: "ring-gray-600",
            hoverRingColor: "hover:ring-gray-600",
            divideColor: "divide-gray-600",
            outlineColor: "outline-gray-600",
            focusRingColor: "focus:ring-gray-600",
        },
        700: {
            bgColor: "bg-gray-700",
            hoverBgColor: "hover:bg-gray-700",
            textColor: "text-gray-700",
            hoverTextColor: "hover:text-gray-700",
            borderColor: "border-gray-700",
            hoverBorderColor: "hover:border-gray-700",
            ringColor: "ring-gray-700",
            hoverRingColor: "hover:ring-gray-700",
            divideColor: "divide-gray-700",
            outlineColor: "outline-gray-700",
            focusRingColor: "focus:ring-gray-700",
        },
        800: {
            bgColor: "bg-gray-800",
            hoverBgColor: "hover:bg-gray-800",
            textColor: "text-gray-800",
            hoverTextColor: "hover:text-gray-800",
            borderColor: "border-gray-800",
            hoverBorderColor: "hover:border-gray-800",
            ringColor: "ring-gray-800",
            hoverRingColor: "hover:ring-gray-800",
            divideColor: "divide-gray-800",
            outlineColor: "outline-gray-800",
            focusRingColor: "focus:ring-gray-800",
        },
        900: {
            bgColor: "bg-gray-900",
            hoverBgColor: "hover:bg-gray-900",
            textColor: "text-gray-900",
            hoverTextColor: "hover:text-gray-900",
            borderColor: "border-gray-900",
            hoverBorderColor: "hover:border-gray-900",
            ringColor: "ring-gray-900",
            hoverRingColor: "hover:ring-gray-900",
            divideColor: "divide-gray-900",
            outlineColor: "outline-gray-900",
            focusRingColor: "focus:ring-gray-900",
        },
    },
    zinc: {
        50: {
            bgColor: "bg-zinc-50",
            hoverBgColor: "hover:bg-zinc-50",
            textColor: "text-zinc-50",
            hoverTextColor: "hover:text-zinc-50",
            borderColor: "border-zinc-50",
            hoverBorderColor: "hover:border-zinc-50",
            ringColor: "ring-zinc-50",
            hoverRingColor: "hover:ring-zinc-50",
            divideColor: "divide-zinc-50",
            outlineColor: "outline-zinc-50",
            focusRingColor: "focus:ring-zinc-50",
        },
        100: {
            bgColor: "bg-zinc-100",
            hoverBgColor: "hover:bg-zinc-100",
            textColor: "text-zinc-100",
            hoverTextColor: "hover:text-zinc-100",
            borderColor: "border-zinc-100",
            hoverBorderColor: "hover:border-zinc-100",
            ringColor: "ring-zinc-100",
            hoverRingColor: "hover:ring-zinc-100",
            divideColor: "divide-zinc-100",
            outlineColor: "outline-zinc-100",
            focusRingColor: "focus:ring-zinc-100",
        },
        200: {
            bgColor: "bg-zinc-200",
            hoverBgColor: "hover:bg-zinc-200",
            textColor: "text-zinc-200",
            hoverTextColor: "hover:text-zinc-200",
            borderColor: "border-zinc-200",
            hoverBorderColor: "hover:border-zinc-200",
            ringColor: "ring-zinc-200",
            hoverRingColor: "hover:ring-zinc-200",
            divideColor: "divide-zinc-200",
            outlineColor: "outline-zinc-200",
            focusRingColor: "focus:ring-zinc-200",
        },
        300: {
            bgColor: "bg-zinc-300",
            hoverBgColor: "hover:bg-zinc-300",
            textColor: "text-zinc-300",
            hoverTextColor: "hover:text-zinc-300",
            borderColor: "border-zinc-300",
            hoverBorderColor: "hover:border-zinc-300",
            ringColor: "ring-zinc-300",
            hoverRingColor: "hover:ring-zinc-300",
            divideColor: "divide-zinc-300",
            outlineColor: "outline-zinc-300",
            focusRingColor: "focus:ring-zinc-300",
        },
        400: {
            bgColor: "bg-zinc-400",
            hoverBgColor: "hover:bg-zinc-400",
            textColor: "text-zinc-400",
            hoverTextColor: "hover:text-zinc-400",
            borderColor: "border-zinc-400",
            hoverBorderColor: "hover:border-zinc-400",
            ringColor: "ring-zinc-400",
            hoverRingColor: "hover:ring-zinc-400",
            divideColor: "divide-zinc-400",
            outlineColor: "outline-zinc-400",
            focusRingColor: "focus:ring-zinc-400",
        },
        500: {
            bgColor: "bg-zinc-500",
            hoverBgColor: "hover:bg-zinc-500",
            textColor: "text-zinc-500",
            hoverTextColor: "hover:text-zinc-500",
            borderColor: "border-zinc-500",
            hoverBorderColor: "hover:border-zinc-500",
            ringColor: "ring-zinc-500",
            hoverRingColor: "hover:ring-zinc-500",
            divideColor: "divide-zinc-500",
            outlineColor: "outline-zinc-500",
            focusRingColor: "focus:ring-zinc-500",
        },
        600: {
            bgColor: "bg-zinc-600",
            hoverBgColor: "hover:bg-zinc-600",
            textColor: "text-zinc-600",
            hoverTextColor: "hover:text-zinc-600",
            borderColor: "border-zinc-600",
            hoverBorderColor: "hover:border-zinc-600",
            ringColor: "ring-zinc-600",
            hoverRingColor: "hover:ring-zinc-600",
            divideColor: "divide-zinc-600",
            outlineColor: "outline-zinc-600",
            focusRingColor: "focus:ring-zinc-600",
        },
        700: {
            bgColor: "bg-zinc-700",
            hoverBgColor: "hover:bg-zinc-700",
            textColor: "text-zinc-700",
            hoverTextColor: "hover:text-zinc-700",
            borderColor: "border-zinc-700",
            hoverBorderColor: "hover:border-zinc-700",
            ringColor: "ring-zinc-700",
            hoverRingColor: "hover:ring-zinc-700",
            divideColor: "divide-zinc-700",
            outlineColor: "outline-zinc-700",
            focusRingColor: "focus:ring-zinc-700",
        },
        800: {
            bgColor: "bg-zinc-800",
            hoverBgColor: "hover:bg-zinc-800",
            textColor: "text-zinc-800",
            hoverTextColor: "hover:text-zinc-800",
            borderColor: "border-zinc-800",
            hoverBorderColor: "hover:border-zinc-800",
            ringColor: "ring-zinc-800",
            hoverRingColor: "hover:ring-zinc-800",
            divideColor: "divide-zinc-800",
            outlineColor: "outline-zinc-800",
            focusRingColor: "focus:ring-zinc-800",
        },
        900: {
            bgColor: "bg-zinc-900",
            hoverBgColor: "hover:bg-zinc-900",
            textColor: "text-zinc-900",
            hoverTextColor: "hover:text-zinc-900",
            borderColor: "border-zinc-900",
            hoverBorderColor: "hover:border-zinc-900",
            ringColor: "ring-zinc-900",
            hoverRingColor: "hover:ring-zinc-900",
            divideColor: "divide-zinc-900",
            outlineColor: "outline-zinc-900",
            focusRingColor: "focus:ring-zinc-900",
        },
    },
    neutral: {
        50: {
            bgColor: "bg-neutral-50",
            hoverBgColor: "hover:bg-neutral-50",
            textColor: "text-neutral-50",
            hoverTextColor: "hover:text-neutral-50",
            borderColor: "border-neutral-50",
            hoverBorderColor: "hover:border-neutral-50",
            ringColor: "ring-neutral-50",
            hoverRingColor: "hover:ring-neutral-50",
            divideColor: "divide-neutral-50",
            outlineColor: "outline-neutral-50",
            focusRingColor: "focus:ring-neutral-50",
        },
        100: {
            bgColor: "bg-neutral-100",
            hoverBgColor: "hover:bg-neutral-100",
            textColor: "text-neutral-100",
            hoverTextColor: "hover:text-neutral-100",
            borderColor: "border-neutral-100",
            hoverBorderColor: "hover:border-neutral-100",
            ringColor: "ring-neutral-100",
            hoverRingColor: "hover:ring-neutral-100",
            divideColor: "divide-neutral-100",
            outlineColor: "outline-neutral-100",
            focusRingColor: "focus:ring-neutral-100",
        },
        200: {
            bgColor: "bg-neutral-200",
            hoverBgColor: "hover:bg-neutral-200",
            textColor: "text-neutral-200",
            hoverTextColor: "hover:text-neutral-200",
            borderColor: "border-neutral-200",
            hoverBorderColor: "hover:border-neutral-200",
            ringColor: "ring-neutral-200",
            hoverRingColor: "hover:ring-neutral-200",
            divideColor: "divide-neutral-200",
            outlineColor: "outline-neutral-200",
            focusRingColor: "focus:ring-neutral-200",
        },
        300: {
            bgColor: "bg-neutral-300",
            hoverBgColor: "hover:bg-neutral-300",
            textColor: "text-neutral-300",
            hoverTextColor: "hover:text-neutral-300",
            borderColor: "border-neutral-300",
            hoverBorderColor: "hover:border-neutral-300",
            ringColor: "ring-neutral-300",
            hoverRingColor: "hover:ring-neutral-300",
            divideColor: "divide-neutral-300",
            outlineColor: "outline-neutral-300",
            focusRingColor: "focus:ring-neutral-300",
        },
        400: {
            bgColor: "bg-neutral-400",
            hoverBgColor: "hover:bg-neutral-400",
            textColor: "text-neutral-400",
            hoverTextColor: "hover:text-neutral-400",
            borderColor: "border-neutral-400",
            hoverBorderColor: "hover:border-neutral-400",
            ringColor: "ring-neutral-400",
            hoverRingColor: "hover:ring-neutral-400",
            divideColor: "divide-neutral-400",
            outlineColor: "outline-neutral-400",
            focusRingColor: "focus:ring-neutral-400",
        },
        500: {
            bgColor: "bg-neutral-500",
            hoverBgColor: "hover:bg-neutral-500",
            textColor: "text-neutral-500",
            hoverTextColor: "hover:text-neutral-500",
            borderColor: "border-neutral-500",
            hoverBorderColor: "hover:border-neutral-500",
            ringColor: "ring-neutral-500",
            hoverRingColor: "hover:ring-neutral-500",
            divideColor: "divide-neutral-500",
            outlineColor: "outline-neutral-500",
            focusRingColor: "focus:ring-neutral-500",
        },
        600: {
            bgColor: "bg-neutral-600",
            hoverBgColor: "hover:bg-neutral-600",
            textColor: "text-neutral-600",
            hoverTextColor: "hover:text-neutral-600",
            borderColor: "border-neutral-600",
            hoverBorderColor: "hover:border-neutral-600",
            ringColor: "ring-neutral-600",
            hoverRingColor: "hover:ring-neutral-600",
            divideColor: "divide-neutral-600",
            outlineColor: "outline-neutral-600",
            focusRingColor: "focus:ring-neutral-600",
        },
        700: {
            bgColor: "bg-neutral-700",
            hoverBgColor: "hover:bg-neutral-700",
            textColor: "text-neutral-700",
            hoverTextColor: "hover:text-neutral-700",
            borderColor: "border-neutral-700",
            hoverBorderColor: "hover:border-neutral-700",
            ringColor: "ring-neutral-700",
            hoverRingColor: "hover:ring-neutral-700",
            divideColor: "divide-neutral-700",
            outlineColor: "outline-neutral-700",
            focusRingColor: "focus:ring-neutral-700",
        },
        800: {
            bgColor: "bg-neutral-800",
            hoverBgColor: "hover:bg-neutral-800",
            textColor: "text-neutral-800",
            hoverTextColor: "hover:text-neutral-800",
            borderColor: "border-neutral-800",
            hoverBorderColor: "hover:border-neutral-800",
            ringColor: "ring-neutral-800",
            hoverRingColor: "hover:ring-neutral-800",
            divideColor: "divide-neutral-800",
            outlineColor: "outline-neutral-800",
            focusRingColor: "focus:ring-neutral-800",
        },
        900: {
            bgColor: "bg-neutral-900",
            hoverBgColor: "hover:bg-neutral-900",
            textColor: "text-neutral-900",
            hoverTextColor: "hover:text-neutral-900",
            borderColor: "border-neutral-900",
            hoverBorderColor: "hover:border-neutral-900",
            ringColor: "ring-neutral-900",
            hoverRingColor: "hover:ring-neutral-900",
            divideColor: "divide-neutral-900",
            outlineColor: "outline-neutral-900",
            focusRingColor: "focus:ring-neutral-900",
        },
    },
    stone: {
        50: {
            bgColor: "bg-stone-50",
            hoverBgColor: "hover:bg-stone-50",
            textColor: "text-stone-50",
            hoverTextColor: "hover:text-stone-50",
            borderColor: "border-stone-50",
            hoverBorderColor: "hover:border-stone-50",
            ringColor: "ring-stone-50",
            hoverRingColor: "hover:ring-stone-50",
            divideColor: "divide-stone-50",
            outlineColor: "outline-stone-50",
            focusRingColor: "focus:ring-stone-50",
        },
        100: {
            bgColor: "bg-stone-100",
            hoverBgColor: "hover:bg-stone-100",
            textColor: "text-stone-100",
            hoverTextColor: "hover:text-stone-100",
            borderColor: "border-stone-100",
            hoverBorderColor: "hover:border-stone-100",
            ringColor: "ring-stone-100",
            hoverRingColor: "hover:ring-stone-100",
            divideColor: "divide-stone-100",
            outlineColor: "outline-stone-100",
            focusRingColor: "focus:ring-stone-100",
        },
        200: {
            bgColor: "bg-stone-200",
            hoverBgColor: "hover:bg-stone-200",
            textColor: "text-stone-200",
            hoverTextColor: "hover:text-stone-200",
            borderColor: "border-stone-200",
            hoverBorderColor: "hover:border-stone-200",
            ringColor: "ring-stone-200",
            hoverRingColor: "hover:ring-stone-200",
            divideColor: "divide-stone-200",
            outlineColor: "outline-stone-200",
            focusRingColor: "focus:ring-stone-200",
        },
        300: {
            bgColor: "bg-stone-300",
            hoverBgColor: "hover:bg-stone-300",
            textColor: "text-stone-300",
            hoverTextColor: "hover:text-stone-300",
            borderColor: "border-stone-300",
            hoverBorderColor: "hover:border-stone-300",
            ringColor: "ring-stone-300",
            hoverRingColor: "hover:ring-stone-300",
            divideColor: "divide-stone-300",
            outlineColor: "outline-stone-300",
            focusRingColor: "focus:ring-stone-300",
        },
        400: {
            bgColor: "bg-stone-400",
            hoverBgColor: "hover:bg-stone-400",
            textColor: "text-stone-400",
            hoverTextColor: "hover:text-stone-400",
            borderColor: "border-stone-400",
            hoverBorderColor: "hover:border-stone-400",
            ringColor: "ring-stone-400",
            hoverRingColor: "hover:ring-stone-400",
            divideColor: "divide-stone-400",
            outlineColor: "outline-stone-400",
            focusRingColor: "focus:ring-stone-400",
        },
        500: {
            bgColor: "bg-stone-500",
            hoverBgColor: "hover:bg-stone-500",
            textColor: "text-stone-500",
            hoverTextColor: "hover:text-stone-500",
            borderColor: "border-stone-500",
            hoverBorderColor: "hover:border-stone-500",
            ringColor: "ring-stone-500",
            hoverRingColor: "hover:ring-stone-500",
            divideColor: "divide-stone-500",
            outlineColor: "outline-stone-500",
            focusRingColor: "focus:ring-stone-500",
        },
        600: {
            bgColor: "bg-stone-600",
            hoverBgColor: "hover:bg-stone-600",
            textColor: "text-stone-600",
            hoverTextColor: "hover:text-stone-600",
            borderColor: "border-stone-600",
            hoverBorderColor: "hover:border-stone-600",
            ringColor: "ring-stone-600",
            hoverRingColor: "hover:ring-stone-600",
            divideColor: "divide-stone-600",
            outlineColor: "outline-stone-600",
            focusRingColor: "focus:ring-stone-600",
        },
        700: {
            bgColor: "bg-stone-700",
            hoverBgColor: "hover:bg-stone-700",
            textColor: "text-stone-700",
            hoverTextColor: "hover:text-stone-700",
            borderColor: "border-stone-700",
            hoverBorderColor: "hover:border-stone-700",
            ringColor: "ring-stone-700",
            hoverRingColor: "hover:ring-stone-700",
            divideColor: "divide-stone-700",
            outlineColor: "outline-stone-700",
            focusRingColor: "focus:ring-stone-700",
        },
        800: {
            bgColor: "bg-stone-800",
            hoverBgColor: "hover:bg-stone-800",
            textColor: "text-stone-800",
            hoverTextColor: "hover:text-stone-800",
            borderColor: "border-stone-800",
            hoverBorderColor: "hover:border-stone-800",
            ringColor: "ring-stone-800",
            hoverRingColor: "hover:ring-stone-800",
            divideColor: "divide-stone-800",
            outlineColor: "outline-stone-800",
            focusRingColor: "focus:ring-stone-800",
        },
        900: {
            bgColor: "bg-stone-900",
            hoverBgColor: "hover:bg-stone-900",
            textColor: "text-stone-900",
            hoverTextColor: "hover:text-stone-900",
            borderColor: "border-stone-900",
            hoverBorderColor: "hover:border-stone-900",
            ringColor: "ring-stone-900",
            hoverRingColor: "hover:ring-stone-900",
            divideColor: "divide-stone-900",
            outlineColor: "outline-stone-900",
            focusRingColor: "focus:ring-stone-900",
        },
    },
    red: {
        50: {
            bgColor: "bg-red-50",
            hoverBgColor: "hover:bg-red-50",
            textColor: "text-red-50",
            hoverTextColor: "hover:text-red-50",
            borderColor: "border-red-50",
            hoverBorderColor: "hover:border-red-50",
            ringColor: "ring-red-50",
            hoverRingColor: "hover:ring-red-50",
            divideColor: "divide-red-50",
            outlineColor: "outline-red-50",
            focusRingColor: "focus:ring-red-50",
        },
        100: {
            bgColor: "bg-red-100",
            hoverBgColor: "hover:bg-red-100",
            textColor: "text-red-100",
            hoverTextColor: "hover:text-red-100",
            borderColor: "border-red-100",
            hoverBorderColor: "hover:border-red-100",
            ringColor: "ring-red-100",
            hoverRingColor: "hover:ring-red-100",
            divideColor: "divide-red-100",
            outlineColor: "outline-red-100",
            focusRingColor: "focus:ring-red-100",
        },
        200: {
            bgColor: "bg-red-200",
            hoverBgColor: "hover:bg-red-200",
            textColor: "text-red-200",
            hoverTextColor: "hover:text-red-200",
            borderColor: "border-red-200",
            hoverBorderColor: "hover:border-red-200",
            ringColor: "ring-red-200",
            hoverRingColor: "hover:ring-red-200",
            divideColor: "divide-red-200",
            outlineColor: "outline-red-200",
            focusRingColor: "focus:ring-red-200",
        },
        300: {
            bgColor: "bg-red-300",
            hoverBgColor: "hover:bg-red-300",
            textColor: "text-red-300",
            hoverTextColor: "hover:text-red-300",
            borderColor: "border-red-300",
            hoverBorderColor: "hover:border-red-300",
            ringColor: "ring-red-300",
            hoverRingColor: "hover:ring-red-300",
            divideColor: "divide-red-300",
            outlineColor: "outline-red-300",
            focusRingColor: "focus:ring-red-300",
        },
        400: {
            bgColor: "bg-red-400",
            hoverBgColor: "hover:bg-red-400",
            textColor: "text-red-400",
            hoverTextColor: "hover:text-red-400",
            borderColor: "border-red-400",
            hoverBorderColor: "hover:border-red-400",
            ringColor: "ring-red-400",
            hoverRingColor: "hover:ring-red-400",
            divideColor: "divide-red-400",
            outlineColor: "outline-red-400",
            focusRingColor: "focus:ring-red-400",
        },
        500: {
            bgColor: "bg-red-500",
            hoverBgColor: "hover:bg-red-500",
            textColor: "text-red-500",
            hoverTextColor: "hover:text-red-500",
            borderColor: "border-red-500",
            hoverBorderColor: "hover:border-red-500",
            ringColor: "ring-red-500",
            hoverRingColor: "hover:ring-red-500",
            divideColor: "divide-red-500",
            outlineColor: "outline-red-500",
            focusRingColor: "focus:ring-red-500",
        },
        600: {
            bgColor: "bg-red-600",
            hoverBgColor: "hover:bg-red-600",
            textColor: "text-red-600",
            hoverTextColor: "hover:text-red-600",
            borderColor: "border-red-600",
            hoverBorderColor: "hover:border-red-600",
            ringColor: "ring-red-600",
            hoverRingColor: "hover:ring-red-600",
            divideColor: "divide-red-600",
            outlineColor: "outline-red-600",
            focusRingColor: "focus:ring-red-600",
        },
        700: {
            bgColor: "bg-red-700",
            hoverBgColor: "hover:bg-red-700",
            textColor: "text-red-700",
            hoverTextColor: "hover:text-red-700",
            borderColor: "border-red-700",
            hoverBorderColor: "hover:border-red-700",
            ringColor: "ring-red-700",
            hoverRingColor: "hover:ring-red-700",
            divideColor: "divide-red-700",
            outlineColor: "outline-red-700",
            focusRingColor: "focus:ring-red-700",
        },
        800: {
            bgColor: "bg-red-800",
            hoverBgColor: "hover:bg-red-800",
            textColor: "text-red-800",
            hoverTextColor: "hover:text-red-800",
            borderColor: "border-red-800",
            hoverBorderColor: "hover:border-red-800",
            ringColor: "ring-red-800",
            hoverRingColor: "hover:ring-red-800",
            divideColor: "divide-red-800",
            outlineColor: "outline-red-800",
            focusRingColor: "focus:ring-red-800",
        },
        900: {
            bgColor: "bg-red-900",
            hoverBgColor: "hover:bg-red-900",
            textColor: "text-red-900",
            hoverTextColor: "hover:text-red-900",
            borderColor: "border-red-900",
            hoverBorderColor: "hover:border-red-900",
            ringColor: "ring-red-900",
            hoverRingColor: "hover:ring-red-900",
            divideColor: "divide-red-900",
            outlineColor: "outline-red-900",
            focusRingColor: "focus:ring-red-900",
        },
    },
    orange: {
        50: {
            bgColor: "bg-orange-50",
            hoverBgColor: "hover:bg-orange-50",
            textColor: "text-orange-50",
            hoverTextColor: "hover:text-orange-50",
            borderColor: "border-orange-50",
            hoverBorderColor: "hover:border-orange-50",
            ringColor: "ring-orange-50",
            hoverRingColor: "hover:ring-orange-50",
            divideColor: "divide-orange-50",
            outlineColor: "outline-orange-50",
            focusRingColor: "focus:ring-orange-50",
        },
        100: {
            bgColor: "bg-orange-100",
            hoverBgColor: "hover:bg-orange-100",
            textColor: "text-orange-100",
            hoverTextColor: "hover:text-orange-100",
            borderColor: "border-orange-100",
            hoverBorderColor: "hover:border-orange-100",
            ringColor: "ring-orange-100",
            hoverRingColor: "hover:ring-orange-100",
            divideColor: "divide-orange-100",
            outlineColor: "outline-orange-100",
            focusRingColor: "focus:ring-orange-100",
        },
        200: {
            bgColor: "bg-orange-200",
            hoverBgColor: "hover:bg-orange-200",
            textColor: "text-orange-200",
            hoverTextColor: "hover:text-orange-200",
            borderColor: "border-orange-200",
            hoverBorderColor: "hover:border-orange-200",
            ringColor: "ring-orange-200",
            hoverRingColor: "hover:ring-orange-200",
            divideColor: "divide-orange-200",
            outlineColor: "outline-orange-200",
            focusRingColor: "focus:ring-orange-200",
        },
        300: {
            bgColor: "bg-orange-300",
            hoverBgColor: "hover:bg-orange-300",
            textColor: "text-orange-300",
            hoverTextColor: "hover:text-orange-300",
            borderColor: "border-orange-300",
            hoverBorderColor: "hover:border-orange-300",
            ringColor: "ring-orange-300",
            hoverRingColor: "hover:ring-orange-300",
            divideColor: "divide-orange-300",
            outlineColor: "outline-orange-300",
            focusRingColor: "focus:ring-orange-300",
        },
        400: {
            bgColor: "bg-orange-400",
            hoverBgColor: "hover:bg-orange-400",
            textColor: "text-orange-400",
            hoverTextColor: "hover:text-orange-400",
            borderColor: "border-orange-400",
            hoverBorderColor: "hover:border-orange-400",
            ringColor: "ring-orange-400",
            hoverRingColor: "hover:ring-orange-400",
            divideColor: "divide-orange-400",
            outlineColor: "outline-orange-400",
            focusRingColor: "focus:ring-orange-400",
        },
        500: {
            bgColor: "bg-orange-500",
            hoverBgColor: "hover:bg-orange-500",
            textColor: "text-orange-500",
            hoverTextColor: "hover:text-orange-500",
            borderColor: "border-orange-500",
            hoverBorderColor: "hover:border-orange-500",
            ringColor: "ring-orange-500",
            hoverRingColor: "hover:ring-orange-500",
            divideColor: "divide-orange-500",
            outlineColor: "outline-orange-500",
            focusRingColor: "focus:ring-orange-500",
        },
        600: {
            bgColor: "bg-orange-600",
            hoverBgColor: "hover:bg-orange-600",
            textColor: "text-orange-600",
            hoverTextColor: "hover:text-orange-600",
            borderColor: "border-orange-600",
            hoverBorderColor: "hover:border-orange-600",
            ringColor: "ring-orange-600",
            hoverRingColor: "hover:ring-orange-600",
            divideColor: "divide-orange-600",
            outlineColor: "outline-orange-600",
            focusRingColor: "focus:ring-orange-600",
        },
        700: {
            bgColor: "bg-orange-700",
            hoverBgColor: "hover:bg-orange-700",
            textColor: "text-orange-700",
            hoverTextColor: "hover:text-orange-700",
            borderColor: "border-orange-700",
            hoverBorderColor: "hover:border-orange-700",
            ringColor: "ring-orange-700",
            hoverRingColor: "hover:ring-orange-700",
            divideColor: "divide-orange-700",
            outlineColor: "outline-orange-700",
            focusRingColor: "focus:ring-orange-700",
        },
        800: {
            bgColor: "bg-orange-800",
            hoverBgColor: "hover:bg-orange-800",
            textColor: "text-orange-800",
            hoverTextColor: "hover:text-orange-800",
            borderColor: "border-orange-800",
            hoverBorderColor: "hover:border-orange-800",
            ringColor: "ring-orange-800",
            hoverRingColor: "hover:ring-orange-800",
            divideColor: "divide-orange-800",
            outlineColor: "outline-orange-800",
            focusRingColor: "focus:ring-orange-800",
        },
        900: {
            bgColor: "bg-orange-900",
            hoverBgColor: "hover:bg-orange-900",
            textColor: "text-orange-900",
            hoverTextColor: "hover:text-orange-900",
            borderColor: "border-orange-900",
            hoverBorderColor: "hover:border-orange-900",
            ringColor: "ring-orange-900",
            hoverRingColor: "hover:ring-orange-900",
            divideColor: "divide-orange-900",
            outlineColor: "outline-orange-900",
            focusRingColor: "focus:ring-orange-900",
        },
    },
    amber: {
        50: {
            bgColor: "bg-amber-50",
            hoverBgColor: "hover:bg-amber-50",
            textColor: "text-amber-50",
            hoverTextColor: "hover:text-amber-50",
            borderColor: "border-amber-50",
            hoverBorderColor: "hover:border-amber-50",
            ringColor: "ring-amber-50",
            hoverRingColor: "hover:ring-amber-50",
            divideColor: "divide-amber-50",
            outlineColor: "outline-amber-50",
            focusRingColor: "focus:ring-amber-50",
        },
        100: {
            bgColor: "bg-amber-100",
            hoverBgColor: "hover:bg-amber-100",
            textColor: "text-amber-100",
            hoverTextColor: "hover:text-amber-100",
            borderColor: "border-amber-100",
            hoverBorderColor: "hover:border-amber-100",
            ringColor: "ring-amber-100",
            hoverRingColor: "hover:ring-amber-100",
            divideColor: "divide-amber-100",
            outlineColor: "outline-amber-100",
            focusRingColor: "focus:ring-amber-100",
        },
        200: {
            bgColor: "bg-amber-200",
            hoverBgColor: "hover:bg-amber-200",
            textColor: "text-amber-200",
            hoverTextColor: "hover:text-amber-200",
            borderColor: "border-amber-200",
            hoverBorderColor: "hover:border-amber-200",
            ringColor: "ring-amber-200",
            hoverRingColor: "hover:ring-amber-200",
            divideColor: "divide-amber-200",
            outlineColor: "outline-amber-200",
            focusRingColor: "focus:ring-amber-200",
        },
        300: {
            bgColor: "bg-amber-300",
            hoverBgColor: "hover:bg-amber-300",
            textColor: "text-amber-300",
            hoverTextColor: "hover:text-amber-300",
            borderColor: "border-amber-300",
            hoverBorderColor: "hover:border-amber-300",
            ringColor: "ring-amber-300",
            hoverRingColor: "hover:ring-amber-300",
            divideColor: "divide-amber-300",
            outlineColor: "outline-amber-300",
            focusRingColor: "focus:ring-amber-300",
        },
        400: {
            bgColor: "bg-amber-400",
            hoverBgColor: "hover:bg-amber-400",
            textColor: "text-amber-400",
            hoverTextColor: "hover:text-amber-400",
            borderColor: "border-amber-400",
            hoverBorderColor: "hover:border-amber-400",
            ringColor: "ring-amber-400",
            hoverRingColor: "hover:ring-amber-400",
            divideColor: "divide-amber-400",
            outlineColor: "outline-amber-400",
            focusRingColor: "focus:ring-amber-400",
        },
        500: {
            bgColor: "bg-amber-500",
            hoverBgColor: "hover:bg-amber-500",
            textColor: "text-amber-500",
            hoverTextColor: "hover:text-amber-500",
            borderColor: "border-amber-500",
            hoverBorderColor: "hover:border-amber-500",
            ringColor: "ring-amber-500",
            hoverRingColor: "hover:ring-amber-500",
            divideColor: "divide-amber-500",
            outlineColor: "outline-amber-500",
            focusRingColor: "focus:ring-amber-500",
        },
        600: {
            bgColor: "bg-amber-600",
            hoverBgColor: "hover:bg-amber-600",
            textColor: "text-amber-600",
            hoverTextColor: "hover:text-amber-600",
            borderColor: "border-amber-600",
            hoverBorderColor: "hover:border-amber-600",
            ringColor: "ring-amber-600",
            hoverRingColor: "hover:ring-amber-600",
            divideColor: "divide-amber-600",
            outlineColor: "outline-amber-600",
            focusRingColor: "focus:ring-amber-600",
        },
        700: {
            bgColor: "bg-amber-700",
            hoverBgColor: "hover:bg-amber-700",
            textColor: "text-amber-700",
            hoverTextColor: "hover:text-amber-700",
            borderColor: "border-amber-700",
            hoverBorderColor: "hover:border-amber-700",
            ringColor: "ring-amber-700",
            hoverRingColor: "hover:ring-amber-700",
            divideColor: "divide-amber-700",
            outlineColor: "outline-amber-700",
            focusRingColor: "focus:ring-amber-700",
        },
        800: {
            bgColor: "bg-amber-800",
            hoverBgColor: "hover:bg-amber-800",
            textColor: "text-amber-800",
            hoverTextColor: "hover:text-amber-800",
            borderColor: "border-amber-800",
            hoverBorderColor: "hover:border-amber-800",
            ringColor: "ring-amber-800",
            hoverRingColor: "hover:ring-amber-800",
            divideColor: "divide-amber-800",
            outlineColor: "outline-amber-800",
            focusRingColor: "focus:ring-amber-800",
        },
        900: {
            bgColor: "bg-amber-900",
            hoverBgColor: "hover:bg-amber-900",
            textColor: "text-amber-900",
            hoverTextColor: "hover:text-amber-900",
            borderColor: "border-amber-900",
            hoverBorderColor: "hover:border-amber-900",
            ringColor: "ring-amber-900",
            hoverRingColor: "hover:ring-amber-900",
            divideColor: "divide-amber-900",
            outlineColor: "outline-amber-900",
            focusRingColor: "focus:ring-amber-900",
        },
    },
    yellow: {
        50: {
            bgColor: "bg-yellow-50",
            hoverBgColor: "hover:bg-yellow-50",
            textColor: "text-yellow-50",
            hoverTextColor: "hover:text-yellow-50",
            borderColor: "border-yellow-50",
            hoverBorderColor: "hover:border-yellow-50",
            ringColor: "ring-yellow-50",
            hoverRingColor: "hover:ring-yellow-50",
            divideColor: "divide-yellow-50",
            outlineColor: "outline-yellow-50",
            focusRingColor: "focus:ring-yellow-50",
        },
        100: {
            bgColor: "bg-yellow-100",
            hoverBgColor: "hover:bg-yellow-100",
            textColor: "text-yellow-100",
            hoverTextColor: "hover:text-yellow-100",
            borderColor: "border-yellow-100",
            hoverBorderColor: "hover:border-yellow-100",
            ringColor: "ring-yellow-100",
            hoverRingColor: "hover:ring-yellow-100",
            divideColor: "divide-yellow-100",
            outlineColor: "outline-yellow-100",
            focusRingColor: "focus:ring-yellow-100",
        },
        200: {
            bgColor: "bg-yellow-200",
            hoverBgColor: "hover:bg-yellow-200",
            textColor: "text-yellow-200",
            hoverTextColor: "hover:text-yellow-200",
            borderColor: "border-yellow-200",
            hoverBorderColor: "hover:border-yellow-200",
            ringColor: "ring-yellow-200",
            hoverRingColor: "hover:ring-yellow-200",
            divideColor: "divide-yellow-200",
            outlineColor: "outline-yellow-200",
            focusRingColor: "focus:ring-yellow-200",
        },
        300: {
            bgColor: "bg-yellow-300",
            hoverBgColor: "hover:bg-yellow-300",
            textColor: "text-yellow-300",
            hoverTextColor: "hover:text-yellow-300",
            borderColor: "border-yellow-300",
            hoverBorderColor: "hover:border-yellow-300",
            ringColor: "ring-yellow-300",
            hoverRingColor: "hover:ring-yellow-300",
            divideColor: "divide-yellow-300",
            outlineColor: "outline-yellow-300",
            focusRingColor: "focus:ring-yellow-300",
        },
        400: {
            bgColor: "bg-yellow-400",
            hoverBgColor: "hover:bg-yellow-400",
            textColor: "text-yellow-400",
            hoverTextColor: "hover:text-yellow-400",
            borderColor: "border-yellow-400",
            hoverBorderColor: "hover:border-yellow-400",
            ringColor: "ring-yellow-400",
            hoverRingColor: "hover:ring-yellow-400",
            divideColor: "divide-yellow-400",
            outlineColor: "outline-yellow-400",
            focusRingColor: "focus:ring-yellow-400",
        },
        500: {
            bgColor: "bg-yellow-500",
            hoverBgColor: "hover:bg-yellow-500",
            textColor: "text-yellow-500",
            hoverTextColor: "hover:text-yellow-500",
            borderColor: "border-yellow-500",
            hoverBorderColor: "hover:border-yellow-500",
            ringColor: "ring-yellow-500",
            hoverRingColor: "hover:ring-yellow-500",
            divideColor: "divide-yellow-500",
            outlineColor: "outline-yellow-500",
            focusRingColor: "focus:ring-yellow-500",
        },
        600: {
            bgColor: "bg-yellow-600",
            hoverBgColor: "hover:bg-yellow-600",
            textColor: "text-yellow-600",
            hoverTextColor: "hover:text-yellow-600",
            borderColor: "border-yellow-600",
            hoverBorderColor: "hover:border-yellow-600",
            ringColor: "ring-yellow-600",
            hoverRingColor: "hover:ring-yellow-600",
            divideColor: "divide-yellow-600",
            outlineColor: "outline-yellow-600",
            focusRingColor: "focus:ring-yellow-600",
        },
        700: {
            bgColor: "bg-yellow-700",
            hoverBgColor: "hover:bg-yellow-700",
            textColor: "text-yellow-700",
            hoverTextColor: "hover:text-yellow-700",
            borderColor: "border-yellow-700",
            hoverBorderColor: "hover:border-yellow-700",
            ringColor: "ring-yellow-700",
            hoverRingColor: "hover:ring-yellow-700",
            divideColor: "divide-yellow-700",
            outlineColor: "outline-yellow-700",
            focusRingColor: "focus:ring-yellow-700",
        },
        800: {
            bgColor: "bg-yellow-800",
            hoverBgColor: "hover:bg-yellow-800",
            textColor: "text-yellow-800",
            hoverTextColor: "hover:text-yellow-800",
            borderColor: "border-yellow-800",
            hoverBorderColor: "hover:border-yellow-800",
            ringColor: "ring-yellow-800",
            hoverRingColor: "hover:ring-yellow-800",
            divideColor: "divide-yellow-800",
            outlineColor: "outline-yellow-800",
            focusRingColor: "focus:ring-yellow-800",
        },
        900: {
            bgColor: "bg-yellow-900",
            hoverBgColor: "hover:bg-yellow-900",
            textColor: "text-yellow-900",
            hoverTextColor: "hover:text-yellow-900",
            borderColor: "border-yellow-900",
            hoverBorderColor: "hover:border-yellow-900",
            ringColor: "ring-yellow-900",
            hoverRingColor: "hover:ring-yellow-900",
            divideColor: "divide-yellow-900",
            outlineColor: "outline-yellow-900",
            focusRingColor: "focus:ring-yellow-900",
        },
    },
    lime: {
        50: {
            bgColor: "bg-lime-50",
            hoverBgColor: "hover:bg-lime-50",
            textColor: "text-lime-50",
            hoverTextColor: "hover:text-lime-50",
            borderColor: "border-lime-50",
            hoverBorderColor: "hover:border-lime-50",
            ringColor: "ring-lime-50",
            hoverRingColor: "hover:ring-lime-50",
            divideColor: "divide-lime-50",
            outlineColor: "outline-lime-50",
            focusRingColor: "focus:ring-lime-50",
        },
        100: {
            bgColor: "bg-lime-100",
            hoverBgColor: "hover:bg-lime-100",
            textColor: "text-lime-100",
            hoverTextColor: "hover:text-lime-100",
            borderColor: "border-lime-100",
            hoverBorderColor: "hover:border-lime-100",
            ringColor: "ring-lime-100",
            hoverRingColor: "hover:ring-lime-100",
            divideColor: "divide-lime-100",
            outlineColor: "outline-lime-100",
            focusRingColor: "focus:ring-lime-100",
        },
        200: {
            bgColor: "bg-lime-200",
            hoverBgColor: "hover:bg-lime-200",
            textColor: "text-lime-200",
            hoverTextColor: "hover:text-lime-200",
            borderColor: "border-lime-200",
            hoverBorderColor: "hover:border-lime-200",
            ringColor: "ring-lime-200",
            hoverRingColor: "hover:ring-lime-200",
            divideColor: "divide-lime-200",
            outlineColor: "outline-lime-200",
            focusRingColor: "focus:ring-lime-200",
        },
        300: {
            bgColor: "bg-lime-300",
            hoverBgColor: "hover:bg-lime-300",
            textColor: "text-lime-300",
            hoverTextColor: "hover:text-lime-300",
            borderColor: "border-lime-300",
            hoverBorderColor: "hover:border-lime-300",
            ringColor: "ring-lime-300",
            hoverRingColor: "hover:ring-lime-300",
            divideColor: "divide-lime-300",
            outlineColor: "outline-lime-300",
            focusRingColor: "focus:ring-lime-300",
        },
        400: {
            bgColor: "bg-lime-400",
            hoverBgColor: "hover:bg-lime-400",
            textColor: "text-lime-400",
            hoverTextColor: "hover:text-lime-400",
            borderColor: "border-lime-400",
            hoverBorderColor: "hover:border-lime-400",
            ringColor: "ring-lime-400",
            hoverRingColor: "hover:ring-lime-400",
            divideColor: "divide-lime-400",
            outlineColor: "outline-lime-400",
            focusRingColor: "focus:ring-lime-400",
        },
        500: {
            bgColor: "bg-lime-500",
            hoverBgColor: "hover:bg-lime-500",
            textColor: "text-lime-500",
            hoverTextColor: "hover:text-lime-500",
            borderColor: "border-lime-500",
            hoverBorderColor: "hover:border-lime-500",
            ringColor: "ring-lime-500",
            hoverRingColor: "hover:ring-lime-500",
            divideColor: "divide-lime-500",
            outlineColor: "outline-lime-500",
            focusRingColor: "focus:ring-lime-500",
        },
        600: {
            bgColor: "bg-lime-600",
            hoverBgColor: "hover:bg-lime-600",
            textColor: "text-lime-600",
            hoverTextColor: "hover:text-lime-600",
            borderColor: "border-lime-600",
            hoverBorderColor: "hover:border-lime-600",
            ringColor: "ring-lime-600",
            hoverRingColor: "hover:ring-lime-600",
            divideColor: "divide-lime-600",
            outlineColor: "outline-lime-600",
            focusRingColor: "focus:ring-lime-600",
        },
        700: {
            bgColor: "bg-lime-700",
            hoverBgColor: "hover:bg-lime-700",
            textColor: "text-lime-700",
            hoverTextColor: "hover:text-lime-700",
            borderColor: "border-lime-700",
            hoverBorderColor: "hover:border-lime-700",
            ringColor: "ring-lime-700",
            hoverRingColor: "hover:ring-lime-700",
            divideColor: "divide-lime-700",
            outlineColor: "outline-lime-700",
            focusRingColor: "focus:ring-lime-700",
        },
        800: {
            bgColor: "bg-lime-800",
            hoverBgColor: "hover:bg-lime-800",
            textColor: "text-lime-800",
            hoverTextColor: "hover:text-lime-800",
            borderColor: "border-lime-800",
            hoverBorderColor: "hover:border-lime-800",
            ringColor: "ring-lime-800",
            hoverRingColor: "hover:ring-lime-800",
            divideColor: "divide-lime-800",
            outlineColor: "outline-lime-800",
            focusRingColor: "focus:ring-lime-800",
        },
        900: {
            bgColor: "bg-lime-900",
            hoverBgColor: "hover:bg-lime-900",
            textColor: "text-lime-900",
            hoverTextColor: "hover:text-lime-900",
            borderColor: "border-lime-900",
            hoverBorderColor: "hover:border-lime-900",
            ringColor: "ring-lime-900",
            hoverRingColor: "hover:ring-lime-900",
            divideColor: "divide-lime-900",
            outlineColor: "outline-lime-900",
            focusRingColor: "focus:ring-lime-900",
        },
    },
    green: {
        50: {
            bgColor: "bg-green-50",
            hoverBgColor: "hover:bg-green-50",
            textColor: "text-green-50",
            hoverTextColor: "hover:text-green-50",
            borderColor: "border-green-50",
            hoverBorderColor: "hover:border-green-50",
            ringColor: "ring-green-50",
            hoverRingColor: "hover:ring-green-50",
            divideColor: "divide-green-50",
            outlineColor: "outline-green-50",
            focusRingColor: "focus:ring-green-50",
        },
        100: {
            bgColor: "bg-green-100",
            hoverBgColor: "hover:bg-green-100",
            textColor: "text-green-100",
            hoverTextColor: "hover:text-green-100",
            borderColor: "border-green-100",
            hoverBorderColor: "hover:border-green-100",
            ringColor: "ring-green-100",
            hoverRingColor: "hover:ring-green-100",
            divideColor: "divide-green-100",
            outlineColor: "outline-green-100",
            focusRingColor: "focus:ring-green-100",
        },
        200: {
            bgColor: "bg-green-200",
            hoverBgColor: "hover:bg-green-200",
            textColor: "text-green-200",
            hoverTextColor: "hover:text-green-200",
            borderColor: "border-green-200",
            hoverBorderColor: "hover:border-green-200",
            ringColor: "ring-green-200",
            hoverRingColor: "hover:ring-green-200",
            divideColor: "divide-green-200",
            outlineColor: "outline-green-200",
            focusRingColor: "focus:ring-green-200",
        },
        300: {
            bgColor: "bg-green-300",
            hoverBgColor: "hover:bg-green-300",
            textColor: "text-green-300",
            hoverTextColor: "hover:text-green-300",
            borderColor: "border-green-300",
            hoverBorderColor: "hover:border-green-300",
            ringColor: "ring-green-300",
            hoverRingColor: "hover:ring-green-300",
            divideColor: "divide-green-300",
            outlineColor: "outline-green-300",
            focusRingColor: "focus:ring-green-300",
        },
        400: {
            bgColor: "bg-green-400",
            hoverBgColor: "hover:bg-green-400",
            textColor: "text-green-400",
            hoverTextColor: "hover:text-green-400",
            borderColor: "border-green-400",
            hoverBorderColor: "hover:border-green-400",
            ringColor: "ring-green-400",
            hoverRingColor: "hover:ring-green-400",
            divideColor: "divide-green-400",
            outlineColor: "outline-green-400",
            focusRingColor: "focus:ring-green-400",
        },
        500: {
            bgColor: "bg-green-500",
            hoverBgColor: "hover:bg-green-500",
            textColor: "text-green-500",
            hoverTextColor: "hover:text-green-500",
            borderColor: "border-green-500",
            hoverBorderColor: "hover:border-green-500",
            ringColor: "ring-green-500",
            hoverRingColor: "hover:ring-green-500",
            divideColor: "divide-green-500",
            outlineColor: "outline-green-500",
            focusRingColor: "focus:ring-green-500",
        },
        600: {
            bgColor: "bg-green-600",
            hoverBgColor: "hover:bg-green-600",
            textColor: "text-green-600",
            hoverTextColor: "hover:text-green-600",
            borderColor: "border-green-600",
            hoverBorderColor: "hover:border-green-600",
            ringColor: "ring-green-600",
            hoverRingColor: "hover:ring-green-600",
            divideColor: "divide-green-600",
            outlineColor: "outline-green-600",
            focusRingColor: "focus:ring-green-600",
        },
        700: {
            bgColor: "bg-green-700",
            hoverBgColor: "hover:bg-green-700",
            textColor: "text-green-700",
            hoverTextColor: "hover:text-green-700",
            borderColor: "border-green-700",
            hoverBorderColor: "hover:border-green-700",
            ringColor: "ring-green-700",
            hoverRingColor: "hover:ring-green-700",
            divideColor: "divide-green-700",
            outlineColor: "outline-green-700",
            focusRingColor: "focus:ring-green-700",
        },
        800: {
            bgColor: "bg-green-800",
            hoverBgColor: "hover:bg-green-800",
            textColor: "text-green-800",
            hoverTextColor: "hover:text-green-800",
            borderColor: "border-green-800",
            hoverBorderColor: "hover:border-green-800",
            ringColor: "ring-green-800",
            hoverRingColor: "hover:ring-green-800",
            divideColor: "divide-green-800",
            outlineColor: "outline-green-800",
            focusRingColor: "focus:ring-green-800",
        },
        900: {
            bgColor: "bg-green-900",
            hoverBgColor: "hover:bg-green-900",
            textColor: "text-green-900",
            hoverTextColor: "hover:text-green-900",
            borderColor: "border-green-900",
            hoverBorderColor: "hover:border-green-900",
            ringColor: "ring-green-900",
            hoverRingColor: "hover:ring-green-900",
            divideColor: "divide-green-900",
            outlineColor: "outline-green-900",
            focusRingColor: "focus:ring-green-900",
        },
    },
    emerald: {
        50: {
            bgColor: "bg-emerald-50",
            hoverBgColor: "hover:bg-emerald-50",
            textColor: "text-emerald-50",
            hoverTextColor: "hover:text-emerald-50",
            borderColor: "border-emerald-50",
            hoverBorderColor: "hover:border-emerald-50",
            ringColor: "ring-emerald-50",
            hoverRingColor: "hover:ring-emerald-50",
            divideColor: "divide-emerald-50",
            outlineColor: "outline-emerald-50",
            focusRingColor: "focus:ring-emerald-50",
        },
        100: {
            bgColor: "bg-emerald-100",
            hoverBgColor: "hover:bg-emerald-100",
            textColor: "text-emerald-100",
            hoverTextColor: "hover:text-emerald-100",
            borderColor: "border-emerald-100",
            hoverBorderColor: "hover:border-emerald-100",
            ringColor: "ring-emerald-100",
            hoverRingColor: "hover:ring-emerald-100",
            divideColor: "divide-emerald-100",
            outlineColor: "outline-emerald-100",
            focusRingColor: "focus:ring-emerald-100",
        },
        200: {
            bgColor: "bg-emerald-200",
            hoverBgColor: "hover:bg-emerald-200",
            textColor: "text-emerald-200",
            hoverTextColor: "hover:text-emerald-200",
            borderColor: "border-emerald-200",
            hoverBorderColor: "hover:border-emerald-200",
            ringColor: "ring-emerald-200",
            hoverRingColor: "hover:ring-emerald-200",
            divideColor: "divide-emerald-200",
            outlineColor: "outline-emerald-200",
            focusRingColor: "focus:ring-emerald-200",
        },
        300: {
            bgColor: "bg-emerald-300",
            hoverBgColor: "hover:bg-emerald-300",
            textColor: "text-emerald-300",
            hoverTextColor: "hover:text-emerald-300",
            borderColor: "border-emerald-300",
            hoverBorderColor: "hover:border-emerald-300",
            ringColor: "ring-emerald-300",
            hoverRingColor: "hover:ring-emerald-300",
            divideColor: "divide-emerald-300",
            outlineColor: "outline-emerald-300",
            focusRingColor: "focus:ring-emerald-300",
        },
        400: {
            bgColor: "bg-emerald-400",
            hoverBgColor: "hover:bg-emerald-400",
            textColor: "text-emerald-400",
            hoverTextColor: "hover:text-emerald-400",
            borderColor: "border-emerald-400",
            hoverBorderColor: "hover:border-emerald-400",
            ringColor: "ring-emerald-400",
            hoverRingColor: "hover:ring-emerald-400",
            divideColor: "divide-emerald-400",
            outlineColor: "outline-emerald-400",
            focusRingColor: "focus:ring-emerald-400",
        },
        500: {
            bgColor: "bg-emerald-500",
            hoverBgColor: "hover:bg-emerald-500",
            textColor: "text-emerald-500",
            hoverTextColor: "hover:text-emerald-500",
            borderColor: "border-emerald-500",
            hoverBorderColor: "hover:border-emerald-500",
            ringColor: "ring-emerald-500",
            hoverRingColor: "hover:ring-emerald-500",
            divideColor: "divide-emerald-500",
            outlineColor: "outline-emerald-500",
            focusRingColor: "focus:ring-emerald-500",
        },
        600: {
            bgColor: "bg-emerald-600",
            hoverBgColor: "hover:bg-emerald-600",
            textColor: "text-emerald-600",
            hoverTextColor: "hover:text-emerald-600",
            borderColor: "border-emerald-600",
            hoverBorderColor: "hover:border-emerald-600",
            ringColor: "ring-emerald-600",
            hoverRingColor: "hover:ring-emerald-600",
            divideColor: "divide-emerald-600",
            outlineColor: "outline-emerald-600",
            focusRingColor: "focus:ring-emerald-600",
        },
        700: {
            bgColor: "bg-emerald-700",
            hoverBgColor: "hover:bg-emerald-700",
            textColor: "text-emerald-700",
            hoverTextColor: "hover:text-emerald-700",
            borderColor: "border-emerald-700",
            hoverBorderColor: "hover:border-emerald-700",
            ringColor: "ring-emerald-700",
            hoverRingColor: "hover:ring-emerald-700",
            divideColor: "divide-emerald-700",
            outlineColor: "outline-emerald-700",
            focusRingColor: "focus:ring-emerald-700",
        },
        800: {
            bgColor: "bg-emerald-800",
            hoverBgColor: "hover:bg-emerald-800",
            textColor: "text-emerald-800",
            hoverTextColor: "hover:text-emerald-800",
            borderColor: "border-emerald-800",
            hoverBorderColor: "hover:border-emerald-800",
            ringColor: "ring-emerald-800",
            hoverRingColor: "hover:ring-emerald-800",
            divideColor: "divide-emerald-800",
            outlineColor: "outline-emerald-800",
            focusRingColor: "focus:ring-emerald-800",
        },
        900: {
            bgColor: "bg-emerald-900",
            hoverBgColor: "hover:bg-emerald-900",
            textColor: "text-emerald-900",
            hoverTextColor: "hover:text-emerald-900",
            borderColor: "border-emerald-900",
            hoverBorderColor: "hover:border-emerald-900",
            ringColor: "ring-emerald-900",
            hoverRingColor: "hover:ring-emerald-900",
            divideColor: "divide-emerald-900",
            outlineColor: "outline-emerald-900",
            focusRingColor: "focus:ring-emerald-900",
        },
    },
    teal: {
        50: {
            bgColor: "bg-teal-50",
            hoverBgColor: "hover:bg-teal-50",
            textColor: "text-teal-50",
            hoverTextColor: "hover:text-teal-50",
            borderColor: "border-teal-50",
            hoverBorderColor: "hover:border-teal-50",
            ringColor: "ring-teal-50",
            hoverRingColor: "hover:ring-teal-50",
            divideColor: "divide-teal-50",
            outlineColor: "outline-teal-50",
            focusRingColor: "focus:ring-teal-50",
        },
        100: {
            bgColor: "bg-teal-100",
            hoverBgColor: "hover:bg-teal-100",
            textColor: "text-teal-100",
            hoverTextColor: "hover:text-teal-100",
            borderColor: "border-teal-100",
            hoverBorderColor: "hover:border-teal-100",
            ringColor: "ring-teal-100",
            hoverRingColor: "hover:ring-teal-100",
            divideColor: "divide-teal-100",
            outlineColor: "outline-teal-100",
            focusRingColor: "focus:ring-teal-100",
        },
        200: {
            bgColor: "bg-teal-200",
            hoverBgColor: "hover:bg-teal-200",
            textColor: "text-teal-200",
            hoverTextColor: "hover:text-teal-200",
            borderColor: "border-teal-200",
            hoverBorderColor: "hover:border-teal-200",
            ringColor: "ring-teal-200",
            hoverRingColor: "hover:ring-teal-200",
            divideColor: "divide-teal-200",
            outlineColor: "outline-teal-200",
            focusRingColor: "focus:ring-teal-200",
        },
        300: {
            bgColor: "bg-teal-300",
            hoverBgColor: "hover:bg-teal-300",
            textColor: "text-teal-300",
            hoverTextColor: "hover:text-teal-300",
            borderColor: "border-teal-300",
            hoverBorderColor: "hover:border-teal-300",
            ringColor: "ring-teal-300",
            hoverRingColor: "hover:ring-teal-300",
            divideColor: "divide-teal-300",
            outlineColor: "outline-teal-300",
            focusRingColor: "focus:ring-teal-300",
        },
        400: {
            bgColor: "bg-teal-400",
            hoverBgColor: "hover:bg-teal-400",
            textColor: "text-teal-400",
            hoverTextColor: "hover:text-teal-400",
            borderColor: "border-teal-400",
            hoverBorderColor: "hover:border-teal-400",
            ringColor: "ring-teal-400",
            hoverRingColor: "hover:ring-teal-400",
            divideColor: "divide-teal-400",
            outlineColor: "outline-teal-400",
            focusRingColor: "focus:ring-teal-400",
        },
        500: {
            bgColor: "bg-teal-500",
            hoverBgColor: "hover:bg-teal-500",
            textColor: "text-teal-500",
            hoverTextColor: "hover:text-teal-500",
            borderColor: "border-teal-500",
            hoverBorderColor: "hover:border-teal-500",
            ringColor: "ring-teal-500",
            hoverRingColor: "hover:ring-teal-500",
            divideColor: "divide-teal-500",
            outlineColor: "outline-teal-500",
            focusRingColor: "focus:ring-teal-500",
        },
        600: {
            bgColor: "bg-teal-600",
            hoverBgColor: "hover:bg-teal-600",
            textColor: "text-teal-600",
            hoverTextColor: "hover:text-teal-600",
            borderColor: "border-teal-600",
            hoverBorderColor: "hover:border-teal-600",
            ringColor: "ring-teal-600",
            hoverRingColor: "hover:ring-teal-600",
            divideColor: "divide-teal-600",
            outlineColor: "outline-teal-600",
            focusRingColor: "focus:ring-teal-600",
        },
        700: {
            bgColor: "bg-teal-700",
            hoverBgColor: "hover:bg-teal-700",
            textColor: "text-teal-700",
            hoverTextColor: "hover:text-teal-700",
            borderColor: "border-teal-700",
            hoverBorderColor: "hover:border-teal-700",
            ringColor: "ring-teal-700",
            hoverRingColor: "hover:ring-teal-700",
            divideColor: "divide-teal-700",
            outlineColor: "outline-teal-700",
            focusRingColor: "focus:ring-teal-700",
        },
        800: {
            bgColor: "bg-teal-800",
            hoverBgColor: "hover:bg-teal-800",
            textColor: "text-teal-800",
            hoverTextColor: "hover:text-teal-800",
            borderColor: "border-teal-800",
            hoverBorderColor: "hover:border-teal-800",
            ringColor: "ring-teal-800",
            hoverRingColor: "hover:ring-teal-800",
            divideColor: "divide-teal-800",
            outlineColor: "outline-teal-800",
            focusRingColor: "focus:ring-teal-800",
        },
        900: {
            bgColor: "bg-teal-900",
            hoverBgColor: "hover:bg-teal-900",
            textColor: "text-teal-900",
            hoverTextColor: "hover:text-teal-900",
            borderColor: "border-teal-900",
            hoverBorderColor: "hover:border-teal-900",
            ringColor: "ring-teal-900",
            hoverRingColor: "hover:ring-teal-900",
            divideColor: "divide-teal-900",
            outlineColor: "outline-teal-900",
            focusRingColor: "focus:ring-teal-900",
        },
    },
    cyan: {
        50: {
            bgColor: "bg-cyan-50",
            hoverBgColor: "hover:bg-cyan-50",
            textColor: "text-cyan-50",
            hoverTextColor: "hover:text-cyan-50",
            borderColor: "border-cyan-50",
            hoverBorderColor: "hover:border-cyan-50",
            ringColor: "ring-cyan-50",
            hoverRingColor: "hover:ring-cyan-50",
            divideColor: "divide-cyan-50",
            outlineColor: "outline-cyan-50",
            focusRingColor: "focus:ring-cyan-50",
        },
        100: {
            bgColor: "bg-cyan-100",
            hoverBgColor: "hover:bg-cyan-100",
            textColor: "text-cyan-100",
            hoverTextColor: "hover:text-cyan-100",
            borderColor: "border-cyan-100",
            hoverBorderColor: "hover:border-cyan-100",
            ringColor: "ring-cyan-100",
            hoverRingColor: "hover:ring-cyan-100",
            divideColor: "divide-cyan-100",
            outlineColor: "outline-cyan-100",
            focusRingColor: "focus:ring-cyan-100",
        },
        200: {
            bgColor: "bg-cyan-200",
            hoverBgColor: "hover:bg-cyan-200",
            textColor: "text-cyan-200",
            hoverTextColor: "hover:text-cyan-200",
            borderColor: "border-cyan-200",
            hoverBorderColor: "hover:border-cyan-200",
            ringColor: "ring-cyan-200",
            hoverRingColor: "hover:ring-cyan-200",
            divideColor: "divide-cyan-200",
            outlineColor: "outline-cyan-200",
            focusRingColor: "focus:ring-cyan-200",
        },
        300: {
            bgColor: "bg-cyan-300",
            hoverBgColor: "hover:bg-cyan-300",
            textColor: "text-cyan-300",
            hoverTextColor: "hover:text-cyan-300",
            borderColor: "border-cyan-300",
            hoverBorderColor: "hover:border-cyan-300",
            ringColor: "ring-cyan-300",
            hoverRingColor: "hover:ring-cyan-300",
            divideColor: "divide-cyan-300",
            outlineColor: "outline-cyan-300",
            focusRingColor: "focus:ring-cyan-300",
        },
        400: {
            bgColor: "bg-cyan-400",
            hoverBgColor: "hover:bg-cyan-400",
            textColor: "text-cyan-400",
            hoverTextColor: "hover:text-cyan-400",
            borderColor: "border-cyan-400",
            hoverBorderColor: "hover:border-cyan-400",
            ringColor: "ring-cyan-400",
            hoverRingColor: "hover:ring-cyan-400",
            divideColor: "divide-cyan-400",
            outlineColor: "outline-cyan-400",
            focusRingColor: "focus:ring-cyan-400",
        },
        500: {
            bgColor: "bg-cyan-500",
            hoverBgColor: "hover:bg-cyan-500",
            textColor: "text-cyan-500",
            hoverTextColor: "hover:text-cyan-500",
            borderColor: "border-cyan-500",
            hoverBorderColor: "hover:border-cyan-500",
            ringColor: "ring-cyan-500",
            hoverRingColor: "hover:ring-cyan-500",
            divideColor: "divide-cyan-500",
            outlineColor: "outline-cyan-500",
            focusRingColor: "focus:ring-cyan-500",
        },
        600: {
            bgColor: "bg-cyan-600",
            hoverBgColor: "hover:bg-cyan-600",
            textColor: "text-cyan-600",
            hoverTextColor: "hover:text-cyan-600",
            borderColor: "border-cyan-600",
            hoverBorderColor: "hover:border-cyan-600",
            ringColor: "ring-cyan-600",
            hoverRingColor: "hover:ring-cyan-600",
            divideColor: "divide-cyan-600",
            outlineColor: "outline-cyan-600",
            focusRingColor: "focus:ring-cyan-600",
        },
        700: {
            bgColor: "bg-cyan-700",
            hoverBgColor: "hover:bg-cyan-700",
            textColor: "text-cyan-700",
            hoverTextColor: "hover:text-cyan-700",
            borderColor: "border-cyan-700",
            hoverBorderColor: "hover:border-cyan-700",
            ringColor: "ring-cyan-700",
            hoverRingColor: "hover:ring-cyan-700",
            divideColor: "divide-cyan-700",
            outlineColor: "outline-cyan-700",
            focusRingColor: "focus:ring-cyan-700",
        },
        800: {
            bgColor: "bg-cyan-800",
            hoverBgColor: "hover:bg-cyan-800",
            textColor: "text-cyan-800",
            hoverTextColor: "hover:text-cyan-800",
            borderColor: "border-cyan-800",
            hoverBorderColor: "hover:border-cyan-800",
            ringColor: "ring-cyan-800",
            hoverRingColor: "hover:ring-cyan-800",
            divideColor: "divide-cyan-800",
            outlineColor: "outline-cyan-800",
            focusRingColor: "focus:ring-cyan-800",
        },
        900: {
            bgColor: "bg-cyan-900",
            hoverBgColor: "hover:bg-cyan-900",
            textColor: "text-cyan-900",
            hoverTextColor: "hover:text-cyan-900",
            borderColor: "border-cyan-900",
            hoverBorderColor: "hover:border-cyan-900",
            ringColor: "ring-cyan-900",
            hoverRingColor: "hover:ring-cyan-900",
            divideColor: "divide-cyan-900",
            outlineColor: "outline-cyan-900",
            focusRingColor: "focus:ring-cyan-900",
        },
    },
    sky: {
        50: {
            bgColor: "bg-sky-50",
            hoverBgColor: "hover:bg-sky-50",
            textColor: "text-sky-50",
            hoverTextColor: "hover:text-sky-50",
            borderColor: "border-sky-50",
            hoverBorderColor: "hover:border-sky-50",
            ringColor: "ring-sky-50",
            hoverRingColor: "hover:ring-sky-50",
            divideColor: "divide-sky-50",
            outlineColor: "outline-sky-50",
            focusRingColor: "focus:ring-sky-50",
        },
        100: {
            bgColor: "bg-sky-100",
            hoverBgColor: "hover:bg-sky-100",
            textColor: "text-sky-100",
            hoverTextColor: "hover:text-sky-100",
            borderColor: "border-sky-100",
            hoverBorderColor: "hover:border-sky-100",
            ringColor: "ring-sky-100",
            hoverRingColor: "hover:ring-sky-100",
            divideColor: "divide-sky-100",
            outlineColor: "outline-sky-100",
            focusRingColor: "focus:ring-sky-100",
        },
        200: {
            bgColor: "bg-sky-200",
            hoverBgColor: "hover:bg-sky-200",
            textColor: "text-sky-200",
            hoverTextColor: "hover:text-sky-200",
            borderColor: "border-sky-200",
            hoverBorderColor: "hover:border-sky-200",
            ringColor: "ring-sky-200",
            hoverRingColor: "hover:ring-sky-200",
            divideColor: "divide-sky-200",
            outlineColor: "outline-sky-200",
            focusRingColor: "focus:ring-sky-200",
        },
        300: {
            bgColor: "bg-sky-300",
            hoverBgColor: "hover:bg-sky-300",
            textColor: "text-sky-300",
            hoverTextColor: "hover:text-sky-300",
            borderColor: "border-sky-300",
            hoverBorderColor: "hover:border-sky-300",
            ringColor: "ring-sky-300",
            hoverRingColor: "hover:ring-sky-300",
            divideColor: "divide-sky-300",
            outlineColor: "outline-sky-300",
            focusRingColor: "focus:ring-sky-300",
        },
        400: {
            bgColor: "bg-sky-400",
            hoverBgColor: "hover:bg-sky-400",
            textColor: "text-sky-400",
            hoverTextColor: "hover:text-sky-400",
            borderColor: "border-sky-400",
            hoverBorderColor: "hover:border-sky-400",
            ringColor: "ring-sky-400",
            hoverRingColor: "hover:ring-sky-400",
            divideColor: "divide-sky-400",
            outlineColor: "outline-sky-400",
            focusRingColor: "focus:ring-sky-400",
        },
        500: {
            bgColor: "bg-sky-500",
            hoverBgColor: "hover:bg-sky-500",
            textColor: "text-sky-500",
            hoverTextColor: "hover:text-sky-500",
            borderColor: "border-sky-500",
            hoverBorderColor: "hover:border-sky-500",
            ringColor: "ring-sky-500",
            hoverRingColor: "hover:ring-sky-500",
            divideColor: "divide-sky-500",
            outlineColor: "outline-sky-500",
            focusRingColor: "focus:ring-sky-500",
        },
        600: {
            bgColor: "bg-sky-600",
            hoverBgColor: "hover:bg-sky-600",
            textColor: "text-sky-600",
            hoverTextColor: "hover:text-sky-600",
            borderColor: "border-sky-600",
            hoverBorderColor: "hover:border-sky-600",
            ringColor: "ring-sky-600",
            hoverRingColor: "hover:ring-sky-600",
            divideColor: "divide-sky-600",
            outlineColor: "outline-sky-600",
            focusRingColor: "focus:ring-sky-600",
        },
        700: {
            bgColor: "bg-sky-700",
            hoverBgColor: "hover:bg-sky-700",
            textColor: "text-sky-700",
            hoverTextColor: "hover:text-sky-700",
            borderColor: "border-sky-700",
            hoverBorderColor: "hover:border-sky-700",
            ringColor: "ring-sky-700",
            hoverRingColor: "hover:ring-sky-700",
            divideColor: "divide-sky-700",
            outlineColor: "outline-sky-700",
            focusRingColor: "focus:ring-sky-700",
        },
        800: {
            bgColor: "bg-sky-800",
            hoverBgColor: "hover:bg-sky-800",
            textColor: "text-sky-800",
            hoverTextColor: "hover:text-sky-800",
            borderColor: "border-sky-800",
            hoverBorderColor: "hover:border-sky-800",
            ringColor: "ring-sky-800",
            hoverRingColor: "hover:ring-sky-800",
            divideColor: "divide-sky-800",
            outlineColor: "outline-sky-800",
            focusRingColor: "focus:ring-sky-800",
        },
        900: {
            bgColor: "bg-sky-900",
            hoverBgColor: "hover:bg-sky-900",
            textColor: "text-sky-900",
            hoverTextColor: "hover:text-sky-900",
            borderColor: "border-sky-900",
            hoverBorderColor: "hover:border-sky-900",
            ringColor: "ring-sky-900",
            hoverRingColor: "hover:ring-sky-900",
            divideColor: "divide-sky-900",
            outlineColor: "outline-sky-900",
            focusRingColor: "focus:ring-sky-900",
        },
    },
    blue: {
        50: {
            bgColor: "bg-blue-50",
            hoverBgColor: "hover:bg-blue-50",
            textColor: "text-blue-50",
            hoverTextColor: "hover:text-blue-50",
            borderColor: "border-blue-50",
            hoverBorderColor: "hover:border-blue-50",
            ringColor: "ring-blue-50",
            hoverRingColor: "hover:ring-blue-50",
            divideColor: "divide-blue-50",
            outlineColor: "outline-blue-50",
            focusRingColor: "focus:ring-blue-50",
        },
        100: {
            bgColor: "bg-blue-100",
            hoverBgColor: "hover:bg-blue-100",
            textColor: "text-blue-100",
            hoverTextColor: "hover:text-blue-100",
            borderColor: "border-blue-100",
            hoverBorderColor: "hover:border-blue-100",
            ringColor: "ring-blue-100",
            hoverRingColor: "hover:ring-blue-100",
            divideColor: "divide-blue-100",
            outlineColor: "outline-blue-100",
            focusRingColor: "focus:ring-blue-100",
        },
        200: {
            bgColor: "bg-blue-200",
            hoverBgColor: "hover:bg-blue-200",
            textColor: "text-blue-200",
            hoverTextColor: "hover:text-blue-200",
            borderColor: "border-blue-200",
            hoverBorderColor: "hover:border-blue-200",
            ringColor: "ring-blue-200",
            hoverRingColor: "hover:ring-blue-200",
            divideColor: "divide-blue-200",
            outlineColor: "outline-blue-200",
            focusRingColor: "focus:ring-blue-200",
        },
        300: {
            bgColor: "bg-blue-300",
            hoverBgColor: "hover:bg-blue-300",
            textColor: "text-blue-300",
            hoverTextColor: "hover:text-blue-300",
            borderColor: "border-blue-300",
            hoverBorderColor: "hover:border-blue-300",
            ringColor: "ring-blue-300",
            hoverRingColor: "hover:ring-blue-300",
            divideColor: "divide-blue-300",
            outlineColor: "outline-blue-300",
            focusRingColor: "focus:ring-blue-300",
        },
        400: {
            bgColor: "bg-blue-400",
            hoverBgColor: "hover:bg-blue-400",
            textColor: "text-blue-400",
            hoverTextColor: "hover:text-blue-400",
            borderColor: "border-blue-400",
            hoverBorderColor: "hover:border-blue-400",
            ringColor: "ring-blue-400",
            hoverRingColor: "hover:ring-blue-400",
            divideColor: "divide-blue-400",
            outlineColor: "outline-blue-400",
            focusRingColor: "focus:ring-blue-400",
        },
        500: {
            bgColor: "bg-blue-500",
            hoverBgColor: "hover:bg-blue-500",
            textColor: "text-blue-500",
            hoverTextColor: "hover:text-blue-500",
            borderColor: "border-blue-500",
            hoverBorderColor: "hover:border-blue-500",
            ringColor: "ring-blue-500",
            hoverRingColor: "hover:ring-blue-500",
            divideColor: "divide-blue-500",
            outlineColor: "outline-blue-500",
            focusRingColor: "focus:ring-blue-500",
        },
        600: {
            bgColor: "bg-blue-600",
            hoverBgColor: "hover:bg-blue-600",
            textColor: "text-blue-600",
            hoverTextColor: "hover:text-blue-600",
            borderColor: "border-blue-600",
            hoverBorderColor: "hover:border-blue-600",
            ringColor: "ring-blue-600",
            hoverRingColor: "hover:ring-blue-600",
            divideColor: "divide-blue-600",
            outlineColor: "outline-blue-600",
            focusRingColor: "focus:ring-blue-600",
        },
        700: {
            bgColor: "bg-blue-700",
            hoverBgColor: "hover:bg-blue-700",
            textColor: "text-blue-700",
            hoverTextColor: "hover:text-blue-700",
            borderColor: "border-blue-700",
            hoverBorderColor: "hover:border-blue-700",
            ringColor: "ring-blue-700",
            hoverRingColor: "hover:ring-blue-700",
            divideColor: "divide-blue-700",
            outlineColor: "outline-blue-700",
            focusRingColor: "focus:ring-blue-700",
        },
        800: {
            bgColor: "bg-blue-800",
            hoverBgColor: "hover:bg-blue-800",
            textColor: "text-blue-800",
            hoverTextColor: "hover:text-blue-800",
            borderColor: "border-blue-800",
            hoverBorderColor: "hover:border-blue-800",
            ringColor: "ring-blue-800",
            hoverRingColor: "hover:ring-blue-800",
            divideColor: "divide-blue-800",
            outlineColor: "outline-blue-800",
            focusRingColor: "focus:ring-blue-800",
        },
        900: {
            bgColor: "bg-blue-900",
            hoverBgColor: "hover:bg-blue-900",
            textColor: "text-blue-900",
            hoverTextColor: "hover:text-blue-900",
            borderColor: "border-blue-900",
            hoverBorderColor: "hover:border-blue-900",
            ringColor: "ring-blue-900",
            hoverRingColor: "hover:ring-blue-900",
            divideColor: "divide-blue-900",
            outlineColor: "outline-blue-900",
            focusRingColor: "focus:ring-blue-900",
        },
    },
    indigo: {
        50: {
            bgColor: "bg-indigo-50",
            hoverBgColor: "hover:bg-indigo-50",
            textColor: "text-indigo-50",
            hoverTextColor: "hover:text-indigo-50",
            borderColor: "border-indigo-50",
            hoverBorderColor: "hover:border-indigo-50",
            ringColor: "ring-indigo-50",
            hoverRingColor: "hover:ring-indigo-50",
            divideColor: "divide-indigo-50",
            outlineColor: "outline-indigo-50",
            focusRingColor: "focus:ring-indigo-50",
        },
        100: {
            bgColor: "bg-indigo-100",
            hoverBgColor: "hover:bg-indigo-100",
            textColor: "text-indigo-100",
            hoverTextColor: "hover:text-indigo-100",
            borderColor: "border-indigo-100",
            hoverBorderColor: "hover:border-indigo-100",
            ringColor: "ring-indigo-100",
            hoverRingColor: "hover:ring-indigo-100",
            divideColor: "divide-indigo-100",
            outlineColor: "outline-indigo-100",
            focusRingColor: "focus:ring-indigo-100",
        },
        200: {
            bgColor: "bg-indigo-200",
            hoverBgColor: "hover:bg-indigo-200",
            textColor: "text-indigo-200",
            hoverTextColor: "hover:text-indigo-200",
            borderColor: "border-indigo-200",
            hoverBorderColor: "hover:border-indigo-200",
            ringColor: "ring-indigo-200",
            hoverRingColor: "hover:ring-indigo-200",
            divideColor: "divide-indigo-200",
            outlineColor: "outline-indigo-200",
            focusRingColor: "focus:ring-indigo-200",
        },
        300: {
            bgColor: "bg-indigo-300",
            hoverBgColor: "hover:bg-indigo-300",
            textColor: "text-indigo-300",
            hoverTextColor: "hover:text-indigo-300",
            borderColor: "border-indigo-300",
            hoverBorderColor: "hover:border-indigo-300",
            ringColor: "ring-indigo-300",
            hoverRingColor: "hover:ring-indigo-300",
            divideColor: "divide-indigo-300",
            outlineColor: "outline-indigo-300",
            focusRingColor: "focus:ring-indigo-300",
        },
        400: {
            bgColor: "bg-indigo-400",
            hoverBgColor: "hover:bg-indigo-400",
            textColor: "text-indigo-400",
            hoverTextColor: "hover:text-indigo-400",
            borderColor: "border-indigo-400",
            hoverBorderColor: "hover:border-indigo-400",
            ringColor: "ring-indigo-400",
            hoverRingColor: "hover:ring-indigo-400",
            divideColor: "divide-indigo-400",
            outlineColor: "outline-indigo-400",
            focusRingColor: "focus:ring-indigo-400",
        },
        500: {
            bgColor: "bg-indigo-500",
            hoverBgColor: "hover:bg-indigo-500",
            textColor: "text-indigo-500",
            hoverTextColor: "hover:text-indigo-500",
            borderColor: "border-indigo-500",
            hoverBorderColor: "hover:border-indigo-500",
            ringColor: "ring-indigo-500",
            hoverRingColor: "hover:ring-indigo-500",
            divideColor: "divide-indigo-500",
            outlineColor: "outline-indigo-500",
            focusRingColor: "focus:ring-indigo-500",
        },
        600: {
            bgColor: "bg-indigo-600",
            hoverBgColor: "hover:bg-indigo-600",
            textColor: "text-indigo-600",
            hoverTextColor: "hover:text-indigo-600",
            borderColor: "border-indigo-600",
            hoverBorderColor: "hover:border-indigo-600",
            ringColor: "ring-indigo-600",
            hoverRingColor: "hover:ring-indigo-600",
            divideColor: "divide-indigo-600",
            outlineColor: "outline-indigo-600",
            focusRingColor: "focus:ring-indigo-600",
        },
        700: {
            bgColor: "bg-indigo-700",
            hoverBgColor: "hover:bg-indigo-700",
            textColor: "text-indigo-700",
            hoverTextColor: "hover:text-indigo-700",
            borderColor: "border-indigo-700",
            hoverBorderColor: "hover:border-indigo-700",
            ringColor: "ring-indigo-700",
            hoverRingColor: "hover:ring-indigo-700",
            divideColor: "divide-indigo-700",
            outlineColor: "outline-indigo-700",
            focusRingColor: "focus:ring-indigo-700",
        },
        800: {
            bgColor: "bg-indigo-800",
            hoverBgColor: "hover:bg-indigo-800",
            textColor: "text-indigo-800",
            hoverTextColor: "hover:text-indigo-800",
            borderColor: "border-indigo-800",
            hoverBorderColor: "hover:border-indigo-800",
            ringColor: "ring-indigo-800",
            hoverRingColor: "hover:ring-indigo-800",
            divideColor: "divide-indigo-800",
            outlineColor: "outline-indigo-800",
            focusRingColor: "focus:ring-indigo-800",
        },
        900: {
            bgColor: "bg-indigo-900",
            hoverBgColor: "hover:bg-indigo-900",
            textColor: "text-indigo-900",
            hoverTextColor: "hover:text-indigo-900",
            borderColor: "border-indigo-900",
            hoverBorderColor: "hover:border-indigo-900",
            ringColor: "ring-indigo-900",
            hoverRingColor: "hover:ring-indigo-900",
            divideColor: "divide-indigo-900",
            outlineColor: "outline-indigo-900",
            focusRingColor: "focus:ring-indigo-900",
        },
    },
    violet: {
        50: {
            bgColor: "bg-violet-50",
            hoverBgColor: "hover:bg-violet-50",
            textColor: "text-violet-50",
            hoverTextColor: "hover:text-violet-50",
            borderColor: "border-violet-50",
            hoverBorderColor: "hover:border-violet-50",
            ringColor: "ring-violet-50",
            hoverRingColor: "hover:ring-violet-50",
            divideColor: "divide-violet-50",
            outlineColor: "outline-violet-50",
            focusRingColor: "focus:ring-violet-50",
        },
        100: {
            bgColor: "bg-violet-100",
            hoverBgColor: "hover:bg-violet-100",
            textColor: "text-violet-100",
            hoverTextColor: "hover:text-violet-100",
            borderColor: "border-violet-100",
            hoverBorderColor: "hover:border-violet-100",
            ringColor: "ring-violet-100",
            hoverRingColor: "hover:ring-violet-100",
            divideColor: "divide-violet-100",
            outlineColor: "outline-violet-100",
            focusRingColor: "focus:ring-violet-100",
        },
        200: {
            bgColor: "bg-violet-200",
            hoverBgColor: "hover:bg-violet-200",
            textColor: "text-violet-200",
            hoverTextColor: "hover:text-violet-200",
            borderColor: "border-violet-200",
            hoverBorderColor: "hover:border-violet-200",
            ringColor: "ring-violet-200",
            hoverRingColor: "hover:ring-violet-200",
            divideColor: "divide-violet-200",
            outlineColor: "outline-violet-200",
            focusRingColor: "focus:ring-violet-200",
        },
        300: {
            bgColor: "bg-violet-300",
            hoverBgColor: "hover:bg-violet-300",
            textColor: "text-violet-300",
            hoverTextColor: "hover:text-violet-300",
            borderColor: "border-violet-300",
            hoverBorderColor: "hover:border-violet-300",
            ringColor: "ring-violet-300",
            hoverRingColor: "hover:ring-violet-300",
            divideColor: "divide-violet-300",
            outlineColor: "outline-violet-300",
            focusRingColor: "focus:ring-violet-300",
        },
        400: {
            bgColor: "bg-violet-400",
            hoverBgColor: "hover:bg-violet-400",
            textColor: "text-violet-400",
            hoverTextColor: "hover:text-violet-400",
            borderColor: "border-violet-400",
            hoverBorderColor: "hover:border-violet-400",
            ringColor: "ring-violet-400",
            hoverRingColor: "hover:ring-violet-400",
            divideColor: "divide-violet-400",
            outlineColor: "outline-violet-400",
            focusRingColor: "focus:ring-violet-400",
        },
        500: {
            bgColor: "bg-violet-500",
            hoverBgColor: "hover:bg-violet-500",
            textColor: "text-violet-500",
            hoverTextColor: "hover:text-violet-500",
            borderColor: "border-violet-500",
            hoverBorderColor: "hover:border-violet-500",
            ringColor: "ring-violet-500",
            hoverRingColor: "hover:ring-violet-500",
            divideColor: "divide-violet-500",
            outlineColor: "outline-violet-500",
            focusRingColor: "focus:ring-violet-500",
        },
        600: {
            bgColor: "bg-violet-600",
            hoverBgColor: "hover:bg-violet-600",
            textColor: "text-violet-600",
            hoverTextColor: "hover:text-violet-600",
            borderColor: "border-violet-600",
            hoverBorderColor: "hover:border-violet-600",
            ringColor: "ring-violet-600",
            hoverRingColor: "hover:ring-violet-600",
            divideColor: "divide-violet-600",
            outlineColor: "outline-violet-600",
            focusRingColor: "focus:ring-violet-600",
        },
        700: {
            bgColor: "bg-violet-700",
            hoverBgColor: "hover:bg-violet-700",
            textColor: "text-violet-700",
            hoverTextColor: "hover:text-violet-700",
            borderColor: "border-violet-700",
            hoverBorderColor: "hover:border-violet-700",
            ringColor: "ring-violet-700",
            hoverRingColor: "hover:ring-violet-700",
            divideColor: "divide-violet-700",
            outlineColor: "outline-violet-700",
            focusRingColor: "focus:ring-violet-700",
        },
        800: {
            bgColor: "bg-violet-800",
            hoverBgColor: "hover:bg-violet-800",
            textColor: "text-violet-800",
            hoverTextColor: "hover:text-violet-800",
            borderColor: "border-violet-800",
            hoverBorderColor: "hover:border-violet-800",
            ringColor: "ring-violet-800",
            hoverRingColor: "hover:ring-violet-800",
            divideColor: "divide-violet-800",
            outlineColor: "outline-violet-800",
            focusRingColor: "focus:ring-violet-800",
        },
        900: {
            bgColor: "bg-violet-900",
            hoverBgColor: "hover:bg-violet-900",
            textColor: "text-violet-900",
            hoverTextColor: "hover:text-violet-900",
            borderColor: "border-violet-900",
            hoverBorderColor: "hover:border-violet-900",
            ringColor: "ring-violet-900",
            hoverRingColor: "hover:ring-violet-900",
            divideColor: "divide-violet-900",
            outlineColor: "outline-violet-900",
            focusRingColor: "focus:ring-violet-900",
        },
    },
    purple: {
        50: {
            bgColor: "bg-purple-50",
            hoverBgColor: "hover:bg-purple-50",
            textColor: "text-purple-50",
            hoverTextColor: "hover:text-purple-50",
            borderColor: "border-purple-50",
            hoverBorderColor: "hover:border-purple-50",
            ringColor: "ring-purple-50",
            hoverRingColor: "hover:ring-purple-50",
            divideColor: "divide-purple-50",
            outlineColor: "outline-purple-50",
            focusRingColor: "focus:ring-purple-50",
        },
        100: {
            bgColor: "bg-purple-100",
            hoverBgColor: "hover:bg-purple-100",
            textColor: "text-purple-100",
            hoverTextColor: "hover:text-purple-100",
            borderColor: "border-purple-100",
            hoverBorderColor: "hover:border-purple-100",
            ringColor: "ring-purple-100",
            hoverRingColor: "hover:ring-purple-100",
            divideColor: "divide-purple-100",
            outlineColor: "outline-purple-100",
            focusRingColor: "focus:ring-purple-100",
        },
        200: {
            bgColor: "bg-purple-200",
            hoverBgColor: "hover:bg-purple-200",
            textColor: "text-purple-200",
            hoverTextColor: "hover:text-purple-200",
            borderColor: "border-purple-200",
            hoverBorderColor: "hover:border-purple-200",
            ringColor: "ring-purple-200",
            hoverRingColor: "hover:ring-purple-200",
            divideColor: "divide-purple-200",
            outlineColor: "outline-purple-200",
            focusRingColor: "focus:ring-purple-200",
        },
        300: {
            bgColor: "bg-purple-300",
            hoverBgColor: "hover:bg-purple-300",
            textColor: "text-purple-300",
            hoverTextColor: "hover:text-purple-300",
            borderColor: "border-purple-300",
            hoverBorderColor: "hover:border-purple-300",
            ringColor: "ring-purple-300",
            hoverRingColor: "hover:ring-purple-300",
            divideColor: "divide-purple-300",
            outlineColor: "outline-purple-300",
            focusRingColor: "focus:ring-purple-300",
        },
        400: {
            bgColor: "bg-purple-400",
            hoverBgColor: "hover:bg-purple-400",
            textColor: "text-purple-400",
            hoverTextColor: "hover:text-purple-400",
            borderColor: "border-purple-400",
            hoverBorderColor: "hover:border-purple-400",
            ringColor: "ring-purple-400",
            hoverRingColor: "hover:ring-purple-400",
            divideColor: "divide-purple-400",
            outlineColor: "outline-purple-400",
            focusRingColor: "focus:ring-purple-400",
        },
        500: {
            bgColor: "bg-purple-500",
            hoverBgColor: "hover:bg-purple-500",
            textColor: "text-purple-500",
            hoverTextColor: "hover:text-purple-500",
            borderColor: "border-purple-500",
            hoverBorderColor: "hover:border-purple-500",
            ringColor: "ring-purple-500",
            hoverRingColor: "hover:ring-purple-500",
            divideColor: "divide-purple-500",
            outlineColor: "outline-purple-500",
            focusRingColor: "focus:ring-purple-500",
        },
        600: {
            bgColor: "bg-purple-600",
            hoverBgColor: "hover:bg-purple-600",
            textColor: "text-purple-600",
            hoverTextColor: "hover:text-purple-600",
            borderColor: "border-purple-600",
            hoverBorderColor: "hover:border-purple-600",
            ringColor: "ring-purple-600",
            hoverRingColor: "hover:ring-purple-600",
            divideColor: "divide-purple-600",
            outlineColor: "outline-purple-600",
            focusRingColor: "focus:ring-purple-600",
        },
        700: {
            bgColor: "bg-purple-700",
            hoverBgColor: "hover:bg-purple-700",
            textColor: "text-purple-700",
            hoverTextColor: "hover:text-purple-700",
            borderColor: "border-purple-700",
            hoverBorderColor: "hover:border-purple-700",
            ringColor: "ring-purple-700",
            hoverRingColor: "hover:ring-purple-700",
            divideColor: "divide-purple-700",
            outlineColor: "outline-purple-700",
            focusRingColor: "focus:ring-purple-700",
        },
        800: {
            bgColor: "bg-purple-800",
            hoverBgColor: "hover:bg-purple-800",
            textColor: "text-purple-800",
            hoverTextColor: "hover:text-purple-800",
            borderColor: "border-purple-800",
            hoverBorderColor: "hover:border-purple-800",
            ringColor: "ring-purple-800",
            hoverRingColor: "hover:ring-purple-800",
            divideColor: "divide-purple-800",
            outlineColor: "outline-purple-800",
            focusRingColor: "focus:ring-purple-800",
        },
        900: {
            bgColor: "bg-purple-900",
            hoverBgColor: "hover:bg-purple-900",
            textColor: "text-purple-900",
            hoverTextColor: "hover:text-purple-900",
            borderColor: "border-purple-900",
            hoverBorderColor: "hover:border-purple-900",
            ringColor: "ring-purple-900",
            hoverRingColor: "hover:ring-purple-900",
            divideColor: "divide-purple-900",
            outlineColor: "outline-purple-900",
            focusRingColor: "focus:ring-purple-900",
        },
    },
    fuchsia: {
        50: {
            bgColor: "bg-fuchsia-50",
            hoverBgColor: "hover:bg-fuchsia-50",
            textColor: "text-fuchsia-50",
            hoverTextColor: "hover:text-fuchsia-50",
            borderColor: "border-fuchsia-50",
            hoverBorderColor: "hover:border-fuchsia-50",
            ringColor: "ring-fuchsia-50",
            hoverRingColor: "hover:ring-fuchsia-50",
            divideColor: "divide-fuchsia-50",
            outlineColor: "outline-fuchsia-50",
            focusRingColor: "focus:ring-fuchsia-50",
        },
        100: {
            bgColor: "bg-fuchsia-100",
            hoverBgColor: "hover:bg-fuchsia-100",
            textColor: "text-fuchsia-100",
            hoverTextColor: "hover:text-fuchsia-100",
            borderColor: "border-fuchsia-100",
            hoverBorderColor: "hover:border-fuchsia-100",
            ringColor: "ring-fuchsia-100",
            hoverRingColor: "hover:ring-fuchsia-100",
            divideColor: "divide-fuchsia-100",
            outlineColor: "outline-fuchsia-100",
            focusRingColor: "focus:ring-fuchsia-100",
        },
        200: {
            bgColor: "bg-fuchsia-200",
            hoverBgColor: "hover:bg-fuchsia-200",
            textColor: "text-fuchsia-200",
            hoverTextColor: "hover:text-fuchsia-200",
            borderColor: "border-fuchsia-200",
            hoverBorderColor: "hover:border-fuchsia-200",
            ringColor: "ring-fuchsia-200",
            hoverRingColor: "hover:ring-fuchsia-200",
            divideColor: "divide-fuchsia-200",
            outlineColor: "outline-fuchsia-200",
            focusRingColor: "focus:ring-fuchsia-200",
        },
        300: {
            bgColor: "bg-fuchsia-300",
            hoverBgColor: "hover:bg-fuchsia-300",
            textColor: "text-fuchsia-300",
            hoverTextColor: "hover:text-fuchsia-300",
            borderColor: "border-fuchsia-300",
            hoverBorderColor: "hover:border-fuchsia-300",
            ringColor: "ring-fuchsia-300",
            hoverRingColor: "hover:ring-fuchsia-300",
            divideColor: "divide-fuchsia-300",
            outlineColor: "outline-fuchsia-300",
            focusRingColor: "focus:ring-fuchsia-300",
        },
        400: {
            bgColor: "bg-fuchsia-400",
            hoverBgColor: "hover:bg-fuchsia-400",
            textColor: "text-fuchsia-400",
            hoverTextColor: "hover:text-fuchsia-400",
            borderColor: "border-fuchsia-400",
            hoverBorderColor: "hover:border-fuchsia-400",
            ringColor: "ring-fuchsia-400",
            hoverRingColor: "hover:ring-fuchsia-400",
            divideColor: "divide-fuchsia-400",
            outlineColor: "outline-fuchsia-400",
            focusRingColor: "focus:ring-fuchsia-400",
        },
        500: {
            bgColor: "bg-fuchsia-500",
            hoverBgColor: "hover:bg-fuchsia-500",
            textColor: "text-fuchsia-500",
            hoverTextColor: "hover:text-fuchsia-500",
            borderColor: "border-fuchsia-500",
            hoverBorderColor: "hover:border-fuchsia-500",
            ringColor: "ring-fuchsia-500",
            hoverRingColor: "hover:ring-fuchsia-500",
            divideColor: "divide-fuchsia-500",
            outlineColor: "outline-fuchsia-500",
            focusRingColor: "focus:ring-fuchsia-500",
        },
        600: {
            bgColor: "bg-fuchsia-600",
            hoverBgColor: "hover:bg-fuchsia-600",
            textColor: "text-fuchsia-600",
            hoverTextColor: "hover:text-fuchsia-600",
            borderColor: "border-fuchsia-600",
            hoverBorderColor: "hover:border-fuchsia-600",
            ringColor: "ring-fuchsia-600",
            hoverRingColor: "hover:ring-fuchsia-600",
            divideColor: "divide-fuchsia-600",
            outlineColor: "outline-fuchsia-600",
            focusRingColor: "focus:ring-fuchsia-600",
        },
        700: {
            bgColor: "bg-fuchsia-700",
            hoverBgColor: "hover:bg-fuchsia-700",
            textColor: "text-fuchsia-700",
            hoverTextColor: "hover:text-fuchsia-700",
            borderColor: "border-fuchsia-700",
            hoverBorderColor: "hover:border-fuchsia-700",
            ringColor: "ring-fuchsia-700",
            hoverRingColor: "hover:ring-fuchsia-700",
            divideColor: "divide-fuchsia-700",
            outlineColor: "outline-fuchsia-700",
            focusRingColor: "focus:ring-fuchsia-700",
        },
        800: {
            bgColor: "bg-fuchsia-800",
            hoverBgColor: "hover:bg-fuchsia-800",
            textColor: "text-fuchsia-800",
            hoverTextColor: "hover:text-fuchsia-800",
            borderColor: "border-fuchsia-800",
            hoverBorderColor: "hover:border-fuchsia-800",
            ringColor: "ring-fuchsia-800",
            hoverRingColor: "hover:ring-fuchsia-800",
            divideColor: "divide-fuchsia-800",
            outlineColor: "outline-fuchsia-800",
            focusRingColor: "focus:ring-fuchsia-800",
        },
        900: {
            bgColor: "bg-fuchsia-900",
            hoverBgColor: "hover:bg-fuchsia-900",
            textColor: "text-fuchsia-900",
            hoverTextColor: "hover:text-fuchsia-900",
            borderColor: "border-fuchsia-900",
            hoverBorderColor: "hover:border-fuchsia-900",
            ringColor: "ring-fuchsia-900",
            hoverRingColor: "hover:ring-fuchsia-900",
            divideColor: "divide-fuchsia-900",
            outlineColor: "outline-fuchsia-900",
            focusRingColor: "focus:ring-fuchsia-900",
        },
    },
    pink: {
        50: {
            bgColor: "bg-pink-50",
            hoverBgColor: "hover:bg-pink-50",
            textColor: "text-pink-50",
            hoverTextColor: "hover:text-pink-50",
            borderColor: "border-pink-50",
            hoverBorderColor: "hover:border-pink-50",
            ringColor: "ring-pink-50",
            hoverRingColor: "hover:ring-pink-50",
            divideColor: "divide-pink-50",
            outlineColor: "outline-pink-50",
            focusRingColor: "focus:ring-pink-50",
        },
        100: {
            bgColor: "bg-pink-100",
            hoverBgColor: "hover:bg-pink-100",
            textColor: "text-pink-100",
            hoverTextColor: "hover:text-pink-100",
            borderColor: "border-pink-100",
            hoverBorderColor: "hover:border-pink-100",
            ringColor: "ring-pink-100",
            hoverRingColor: "hover:ring-pink-100",
            divideColor: "divide-pink-100",
            outlineColor: "outline-pink-100",
            focusRingColor: "focus:ring-pink-100",
        },
        200: {
            bgColor: "bg-pink-200",
            hoverBgColor: "hover:bg-pink-200",
            textColor: "text-pink-200",
            hoverTextColor: "hover:text-pink-200",
            borderColor: "border-pink-200",
            hoverBorderColor: "hover:border-pink-200",
            ringColor: "ring-pink-200",
            hoverRingColor: "hover:ring-pink-200",
            divideColor: "divide-pink-200",
            outlineColor: "outline-pink-200",
            focusRingColor: "focus:ring-pink-200",
        },
        300: {
            bgColor: "bg-pink-300",
            hoverBgColor: "hover:bg-pink-300",
            textColor: "text-pink-300",
            hoverTextColor: "hover:text-pink-300",
            borderColor: "border-pink-300",
            hoverBorderColor: "hover:border-pink-300",
            ringColor: "ring-pink-300",
            hoverRingColor: "hover:ring-pink-300",
            divideColor: "divide-pink-300",
            outlineColor: "outline-pink-300",
            focusRingColor: "focus:ring-pink-300",
        },
        400: {
            bgColor: "bg-pink-400",
            hoverBgColor: "hover:bg-pink-400",
            textColor: "text-pink-400",
            hoverTextColor: "hover:text-pink-400",
            borderColor: "border-pink-400",
            hoverBorderColor: "hover:border-pink-400",
            ringColor: "ring-pink-400",
            hoverRingColor: "hover:ring-pink-400",
            divideColor: "divide-pink-400",
            outlineColor: "outline-pink-400",
            focusRingColor: "focus:ring-pink-400",
        },
        500: {
            bgColor: "bg-pink-500",
            hoverBgColor: "hover:bg-pink-500",
            textColor: "text-pink-500",
            hoverTextColor: "hover:text-pink-500",
            borderColor: "border-pink-500",
            hoverBorderColor: "hover:border-pink-500",
            ringColor: "ring-pink-500",
            hoverRingColor: "hover:ring-pink-500",
            divideColor: "divide-pink-500",
            outlineColor: "outline-pink-500",
            focusRingColor: "focus:ring-pink-500",
        },
        600: {
            bgColor: "bg-pink-600",
            hoverBgColor: "hover:bg-pink-600",
            textColor: "text-pink-600",
            hoverTextColor: "hover:text-pink-600",
            borderColor: "border-pink-600",
            hoverBorderColor: "hover:border-pink-600",
            ringColor: "ring-pink-600",
            hoverRingColor: "hover:ring-pink-600",
            divideColor: "divide-pink-600",
            outlineColor: "outline-pink-600",
            focusRingColor: "focus:ring-pink-600",
        },
        700: {
            bgColor: "bg-pink-700",
            hoverBgColor: "hover:bg-pink-700",
            textColor: "text-pink-700",
            hoverTextColor: "hover:text-pink-700",
            borderColor: "border-pink-700",
            hoverBorderColor: "hover:border-pink-700",
            ringColor: "ring-pink-700",
            hoverRingColor: "hover:ring-pink-700",
            divideColor: "divide-pink-700",
            outlineColor: "outline-pink-700",
            focusRingColor: "focus:ring-pink-700",
        },
        800: {
            bgColor: "bg-pink-700",
            hoverBgColor: "hover:bg-pink-700",
            textColor: "text-pink-700",
            hoverTextColor: "hover:text-pink-700",
            borderColor: "border-pink-700",
            hoverBorderColor: "hover:border-pink-700",
            ringColor: "ring-pink-700",
            hoverRingColor: "hover:ring-pink-700",
            divideColor: "divide-pink-700",
            outlineColor: "outline-pink-700",
            focusRingColor: "focus:ring-pink-700",
        },
        900: {
            bgColor: "bg-pink-900",
            hoverBgColor: "hover:bg-pink-900",
            textColor: "text-pink-900",
            hoverTextColor: "hover:text-pink-900",
            borderColor: "border-pink-900",
            hoverBorderColor: "hover:border-pink-900",
            ringColor: "ring-pink-900",
            hoverRingColor: "hover:ring-pink-900",
            divideColor: "divide-pink-900",
            outlineColor: "outline-pink-900",
            focusRingColor: "focus:ring-pink-900",
        },
    },
    rose: {
        50: {
            bgColor: "bg-rose-50",
            hoverBgColor: "hover:bg-rose-50",
            textColor: "text-rose-50",
            hoverTextColor: "hover:text-rose-50",
            borderColor: "border-rose-50",
            hoverBorderColor: "hover:border-rose-50",
            ringColor: "ring-rose-50",
            hoverRingColor: "hover:ring-rose-50",
            divideColor: "divide-rose-50",
            outlineColor: "outline-rose-50",
            focusRingColor: "focus:ring-rose-50",
        },
        100: {
            bgColor: "bg-rose-100",
            hoverBgColor: "hover:bg-rose-100",
            textColor: "text-rose-100",
            hoverTextColor: "hover:text-rose-100",
            borderColor: "border-rose-100",
            hoverBorderColor: "hover:border-rose-100",
            ringColor: "ring-rose-100",
            hoverRingColor: "hover:ring-rose-100",
            divideColor: "divide-rose-100",
            outlineColor: "outline-rose-100",
            focusRingColor: "focus:ring-rose-100",
        },
        200: {
            bgColor: "bg-rose-200",
            hoverBgColor: "hover:bg-rose-200",
            textColor: "text-rose-200",
            hoverTextColor: "hover:text-rose-200",
            borderColor: "border-rose-200",
            hoverBorderColor: "hover:border-rose-200",
            ringColor: "ring-rose-200",
            hoverRingColor: "hover:ring-rose-200",
            divideColor: "divide-rose-200",
            outlineColor: "outline-rose-200",
            focusRingColor: "focus:ring-rose-200",
        },
        300: {
            bgColor: "bg-rose-300",
            hoverBgColor: "hover:bg-rose-300",
            textColor: "text-rose-300",
            hoverTextColor: "hover:text-rose-300",
            borderColor: "border-rose-300",
            hoverBorderColor: "hover:border-rose-300",
            ringColor: "ring-rose-300",
            hoverRingColor: "hover:ring-rose-300",
            divideColor: "divide-rose-300",
            outlineColor: "outline-rose-300",
            focusRingColor: "focus:ring-rose-300",
        },
        400: {
            bgColor: "bg-rose-400",
            hoverBgColor: "hover:bg-rose-400",
            textColor: "text-rose-400",
            hoverTextColor: "hover:text-rose-400",
            borderColor: "border-rose-400",
            hoverBorderColor: "hover:border-rose-400",
            ringColor: "ring-rose-400",
            hoverRingColor: "hover:ring-rose-400",
            divideColor: "divide-rose-400",
            outlineColor: "outline-rose-400",
            focusRingColor: "focus:ring-rose-400",
        },
        500: {
            bgColor: "bg-rose-500",
            hoverBgColor: "hover:bg-rose-500",
            textColor: "text-rose-500",
            hoverTextColor: "hover:text-rose-500",
            borderColor: "border-rose-500",
            hoverBorderColor: "hover:border-rose-500",
            ringColor: "ring-rose-500",
            hoverRingColor: "hover:ring-rose-500",
            divideColor: "divide-rose-500",
            outlineColor: "outline-rose-500",
            focusRingColor: "focus:ring-rose-500",
        },
        600: {
            bgColor: "bg-rose-600",
            hoverBgColor: "hover:bg-rose-600",
            textColor: "text-rose-600",
            hoverTextColor: "hover:text-rose-600",
            borderColor: "border-rose-600",
            hoverBorderColor: "hover:border-rose-600",
            ringColor: "ring-rose-600",
            hoverRingColor: "hover:ring-rose-600",
            divideColor: "divide-rose-600",
            outlineColor: "outline-rose-600",
            focusRingColor: "focus:ring-rose-600",
        },
        700: {
            bgColor: "bg-rose-700",
            hoverBgColor: "hover:bg-rose-700",
            textColor: "text-rose-700",
            hoverTextColor: "hover:text-rose-700",
            borderColor: "border-rose-700",
            hoverBorderColor: "hover:border-rose-700",
            ringColor: "ring-rose-700",
            hoverRingColor: "hover:ring-rose-700",
            divideColor: "divide-rose-700",
            outlineColor: "outline-rose-700",
            focusRingColor: "focus:ring-rose-700",
        },
        800: {
            bgColor: "bg-rose-800",
            hoverBgColor: "hover:bg-rose-800",
            textColor: "text-rose-800",
            hoverTextColor: "hover:text-rose-800",
            borderColor: "border-rose-800",
            hoverBorderColor: "hover:border-rose-800",
            ringColor: "ring-rose-800",
            hoverRingColor: "hover:ring-rose-800",
            divideColor: "divide-rose-800",
            outlineColor: "outline-rose-800",
            focusRingColor: "focus:ring-rose-800",
        },
        900: {
            bgColor: "bg-rose-900",
            hoverBgColor: "hover:bg-rose-900",
            textColor: "text-rose-900",
            hoverTextColor: "hover:text-rose-900",
            borderColor: "border-rose-900",
            hoverBorderColor: "hover:border-rose-900",
            ringColor: "ring-rose-900",
            hoverRingColor: "hover:ring-rose-900",
            divideColor: "divide-rose-900",
            outlineColor: "outline-rose-900",
            focusRingColor: "focus:ring-rose-900",
        },
    },
} as const;

export const spacing = {
    none: {
        paddingLeft: "pl-0",
        paddingTop: "pt-0",
        paddingRight: "pr-0",
        paddingBottom: "pb-0",
        marginLeft: "ml-0",
        marginTop: "mt-0",
        marginRight: "mr-0",
        marginBottom: "mb-0",
        negativeMarginLeft: "-ml-0",
        negativeMarginRight: "-mr-0",
        negativeMarginTop: "-mt-0",
        negativeMarginBottom: "-mb-0",
        left: "left-0",
        right: "right-0",
        top: "top-0",
        bottom: "bottom-0",
        spaceX: "space-x-0",
    },
    px: {
        paddingLeft: "pl-px",
        paddingTop: "pt-px",
        paddingRight: "pr-px",
        paddingBottom: "pb-px",
        marginLeft: "ml-px",
        marginTop: "mt-px",
        marginRight: "mr-px",
        marginBottom: "mb-px",
        negativeMarginLeft: "-ml-px",
        negativeMarginRight: "-mr-px",
        negativeMarginTop: "-mt-px",
        negativeMarginBottom: "-mb-px",
        left: "left-px",
        right: "right-px",
        top: "top-px",
        bottom: "bottom-px",
        spaceX: "space-x-px",
    },
    threeXs: {
        paddingLeft: "pl-0.5",
        paddingTop: "pt-0.5",
        paddingRight: "pr-0.5",
        paddingBottom: "pb-0.5",
        marginLeft: "ml-0.5",
        marginTop: "mt-0.5",
        marginRight: "mr-0.5",
        marginBottom: "mb-0.5",
        negativeMarginLeft: "-ml-0.5",
        negativeMarginRight: "-mr-0.5",
        negativeMarginTop: "-mt-0.5",
        negativeMarginBottom: "-mb-0.5",
        left: "left-0.5",
        right: "right-0.5",
        top: "top-0.5",
        bottom: "bottom-0.5",
        spaceX: "space-x-0.5",
    },
    twoXs: {
        paddingLeft: "pl-1",
        paddingTop: "pt-1",
        paddingRight: "pr-1",
        paddingBottom: "pb-1",
        marginLeft: "ml-1",
        marginTop: "mt-1",
        marginRight: "mr-1",
        marginBottom: "mb-1",
        negativeMarginLeft: "-ml-1",
        negativeMarginRight: "-mr-1",
        left: "left-1",
        right: "right-1",
        top: "top-1",
        bottom: "bottom-1",
        spaceX: "space-x-1",
    },
    xs: {
        paddingLeft: "pl-1.5",
        paddingTop: "pt-1.5",
        paddingRight: "pr-1.5",
        paddingBottom: "pb-1.5",
        marginLeft: "ml-1.5",
        marginTop: "mt-1.5",
        marginRight: "mr-1.5",
        marginBottom: "mb-1.5",
        negativeMarginLeft: "-ml-1.5",
        negativeMarginRight: "-mr-1.5",
        negativeMarginTop: "-mt-1.5",
        negativeMarginBottom: "-mb-1.5",
        left: "left-1.5",
        right: "right-1.5",
        top: "top-1.5",
        bottom: "bottom-1.5",
        spaceX: "space-x-1.5",
    },
    sm: {
        paddingLeft: "pl-2",
        paddingTop: "pt-2",
        paddingRight: "pr-2",
        paddingBottom: "pb-2",
        marginLeft: "ml-2",
        marginTop: "mt-2",
        marginRight: "mr-2",
        marginBottom: "mb-2",
        negativeMarginLeft: "-ml-2",
        negativeMarginRight: "-mr-2",
        negativeMarginTop: "-mt-2",
        negativeMarginBottom: "-mb-2",
        left: "left-2",
        right: "right-2",
        top: "left-2",
        bottom: "bottom-2",
        spaceX: "space-x-2",
    },
    md: {
        paddingLeft: "pl-2.5",
        paddingTop: "pt-2.5",
        paddingRight: "pr-2.5",
        paddingBottom: "pb-2.5",
        marginLeft: "ml-2.5",
        marginTop: "mt-2.5",
        marginRight: "mr-2.5",
        marginBottom: "mb-2.5",
        negativeMarginLeft: "-ml-2.5",
        negativeMarginRight: "-mr-2.5",
        negativeMarginTop: "-mt-2.5",
        negativeMarginBottom: "-mb-2.5",
        left: "left-2.5",
        right: "right-2.5",
        top: "top-2.5",
        bottom: "bottom-2.5",
        spaceX: "space-x-2.5",
    },
    lg: {
        paddingLeft: "pl-3",
        paddingTop: "pt-3",
        paddingRight: "pr-3",
        paddingBottom: "pb-3",
        marginLeft: "ml-3",
        marginTop: "mt-3",
        marginRight: "mr-3",
        marginBottom: "mb-3",
        negativeMarginLeft: "-ml-3",
        negativeMarginRight: "-mr-3",
        negativeMarginTop: "-mt-3",
        negativeMarginBottom: "-mb-3",
        left: "left-3",
        right: "right-3",
        top: "top-3",
        bottom: "bottom-3",
        spaceX: "space-x-3",
    },
    xl: {
        paddingLeft: "pl-3.5",
        paddingTop: "pt-3.5",
        paddingRight: "pr-3.5",
        paddingBottom: "pb-3.5",
        marginLeft: "ml-3.5",
        marginTop: "mt-3.5",
        marginRight: "mr-3.5",
        marginBottom: "mb-3.5",
        negativeMarginLeft: "-ml-3.5",
        negativeMarginRight: "-mr-3.5",
        negativeMarginTop: "-mt-3.5",
        negativeMarginBottom: "-mb-3.5",
        left: "left-3.5",
        right: "right-3.5",
        top: "top-3.5",
        bottom: "bottom-3.5",
        spaceX: "space-x-3.5",
    },
    twoXl: {
        paddingLeft: "pl-4",
        paddingTop: "pt-4",
        paddingRight: "pr-4",
        paddingBottom: "pb-4",
        marginLeft: "ml-4",
        marginTop: "mt-4",
        marginRight: "mr-4",
        marginBottom: "mb-4",
        negativeMarginLeft: "-ml-4",
        negativeMarginRight: "-mr-4",
        negativeMarginTop: "-mt-4",
        negativeMarginBottom: "-mb-4",
        left: "left-4",
        right: "right-4",
        top: "top-4",
        bottom: "bottom-4",
        spaceX: "space-x-4",
    },
    threeXl: {
        paddingLeft: "pl-6",
        paddingTop: "pt-6",
        paddingRight: "pr-6",
        paddingBottom: "pb-6",
        marginLeft: "ml-6",
        marginTop: "mt-6",
        marginRight: "mr-6",
        marginBottom: "mb-6",
        negativeMarginLeft: "-ml-6",
        negativeMarginRight: "-mr-6",
        negativeMarginTop: "-mt-6",
        negativeMarginBottom: "-mb-6",
        left: "left-6",
        right: "right-6",
        top: "top-6",
        bottom: "bottom-6",
        spaceX: "space-x-6",
    },
    fourXl: {
        paddingLeft: "pl-8",
        paddingTop: "pt-8",
        paddingRight: "pr-8",
        paddingBottom: "pb-8",
        marginLeft: "ml-8",
        marginTop: "mt-8",
        marginRight: "mr-8",
        marginBottom: "mb-8",
        negativeMarginLeft: "-ml-8",
        negativeMarginRight: "-mr-8",
        negativeMarginTop: "-mt-8",
        negativeMarginBottom: "-mb-8",
        left: "left-8",
        right: "right-8",
        top: "top-8",
        bottom: "bottom-8",
        spaceX: "space-x-8",
    },
} as const;

export const sizing = {
    none: {
        height: "h-0",
        width: "w-0",
    },
    threeXs: {
        height: "h-0.5",
        width: "w-0.5",
    },
    twoXs: {
        height: "h-1",
        width: "w-1",
    },
    xs: {
        height: "h-2",
        width: "w-2",
    },
    sm: {
        height: "h-3",
        width: "w-3",
    },
    md: {
        height: "h-4",
        width: "w-4",
    },
    lg: {
        height: "h-5",
        width: "w-5",
    },
    xl: {
        height: "h-6",
        width: "w-6",
    },
    twoXl: {
        height: "h-7",
        width: "w-7",
    },
    threeXl: {
        height: "h-9",
        width: "w-9",
    },
} as const;

export const iconSizes: { [size: string]: commonTypes.ISizing } = {
    xs: {
        height: sizing.md.height,
        width: sizing.md.width,
    },
    sm: {
        height: sizing.md.height,
        width: sizing.md.width,
    },
    md: {
        height: sizing.md.height,
        width: sizing.md.width,
    },
    lg: {
        height: sizing.lg.height,
        width: sizing.lg.width,
    },
    xl: {
        height: sizing.xl.height,
        width: sizing.xl.width,
    },
} as const;
