import { twMerge } from "@app/common/utils/twMerge";
import React, { HTMLAttributes } from "react";
import { commonTypes } from "../../common";

export interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
    justifyContent?: commonTypes.TJustifyContent;
    alignItems?: commonTypes.TAlignItems;
    spaceX?: commonTypes.TSpaceX;
    truncate?: boolean;
    children: React.ReactNode;
    height?: string;
}

export const Flex = ({
    justifyContent = "justify-between",
    alignItems = "items-center",
    spaceX = "space-x-0",
    truncate = false,
    height = "",
    children,
    className,
    ...restProps
}: IFlexProps) => (
    <div
        className={twMerge(
            "truncate flex w-full mt-0",
            height,
            truncate && "whitespace-nowrap",
            justifyContent,
            alignItems,
            spaceX,
            className
        )}
        {...restProps}>
        {children}
    </div>
);
