import { OutlineIcon } from "@app/common";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { NotificationTypes } from "../../constants/NotificationTypes";
import { INotification } from "./types";

export interface INotificationProps {
    notification: INotification;
    onClose?: (id: string) => void;
    duration?: DurationTypes;
    expire?: number;
}

enum DurationTypes {
    Slow = "slow",
    Normal = "normal",
    Fast = "fast",
}

const durationTimeMap: { [key in DurationTypes]: number } = {
    [DurationTypes.Slow]: 900,
    [DurationTypes.Normal]: 600,
    [DurationTypes.Fast]: 300,
};

export function Notification({ notification, duration = DurationTypes.Normal, onClose, expire }: INotificationProps) {
    const [isVisible, setVisibility] = useState(true);
    // const [expireProcessId, setExpireProcessId] = useState<number>();
    const { type } = notification;
    const durationTime = durationTimeMap[duration];
    const colorClassName = classnames({
        "text-green-400": type === NotificationTypes.Success,
        "text-blue-400": type === NotificationTypes.Info,
        "text-orange-400": type === NotificationTypes.Warning,
        "text-red-400": type === NotificationTypes.Error,
    });

    const durationClassName = classnames({
        "duration-900": duration === DurationTypes.Slow,
        "duration-600": duration === DurationTypes.Normal,
        "duration-300": duration === DurationTypes.Fast,
    });

    const handleClose = () => {
        setVisibility(!isVisible);

        setTimeout(() => {
            onClose && onClose(notification.id);
        }, durationTime);
    };

    useEffect(() => {
        if (expire) {
            const newExpireProcessId = window.setTimeout(handleClose, expire);

            return () => clearTimeout(newExpireProcessId);
        }
    }, []);

    return (
        <Transition
            appear={true}
            show={isVisible}
            as={Fragment}
            enter={`transform ease-out ${durationClassName} transition`}
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave={`transition ease-in ${durationClassName}`}
            leaveFrom="opacity-1000"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <OutlineIcon name="chat" className={classnames("h-6 w-6 ", colorClassName)} aria-hidden="true" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                            <p className="mt-1 text-sm text-gray-500">{notification.msg}</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                            {onClose && (
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={handleClose}>
                                    <span className="sr-only">Close</span>
                                    <OutlineIcon name="x" className="h-5 w-5" aria-hidden="true" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="w-full bg-gray-200 h-1">
                    <Transition
                        className="bg-hc_green-700 h-1 w-[50]"
                        appear={true}
                        show={true}
                        enter="transition-opacity transition-width duration-[5000ms]"
                        enterFrom="opacity-50 transition-width  w-0"
                        enterTo="opacity-100 w-full"></Transition>
                </div>
            </div>
        </Transition>
    );
}
