import React from "react";
import { SpringSpinner } from "react-epic-spinners";

interface ILoadingMaskProps {
    isLoading: boolean;
    children?: JSX.Element | JSX.Element[];
}

export function LoadingMask({ isLoading, children }: ILoadingMaskProps) {
    return !isLoading ? (
        <div>{children}</div>
    ) : (
        <div className="w-full h-full bg-slate-50 flex justify-center items-center relative">
            <SpringSpinner color="#3e4685" size={65} />
        </div>
    );
}
