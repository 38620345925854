import i18nextLibrary from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18nextLibrary.init({
    fallbackLng: "nl",
    debug: true,

    resources: {
        en: {
            common: {
                navigate: {
                    back: "Back",
                },
                search: "Search",
                validate: {
                    "is-required": "Is required",
                    "required": "Is required",
                    "min-length": "Is min length of {{value}}",
                    "max-length": "Is max length of {{value}}",
                    "min": "Is min {{value}}",
                    "max": "Is max {{value}}",
                    "present": "Is present",
                    "email": "Is valid email address",
                },
            },
            app: {
                "new-deployment-tigger": "Please refresh the screen to ensure that you are viewing the most up-to-date version of the code and to prevent any potential errors.",
            },
        },
        nl: {
            common: {
                navigate: {
                    back: "Terug",
                },
                search: "Zoeken",
                validate: {
                    "is-required": "Is verplicht",
                    "required": "Is verplicht",
                    "min-length": "Heeft minimale lengte van {{value}}",
                    "max-length": "Heeft maximale lengte van {{value}}",
                    "min": "Is min {{value}}",
                    "max": "Is max {{value}}",
                    "present": "Is aanwezig",
                    "email": "Is een geldig emailadres",
                },
            },
            app: {
                "new-deployment-tigger": "Please refresh the screen to ensure that you are viewing the most up-to-date version of the code and to prevent any potential errors.",
            },
        },
    },
});

export const i18next = i18nextLibrary;
