import { twMerge } from "@app/common/utils/twMerge";
import React, { ReactNode, HTMLAttributes } from "react";

interface ILabelProps extends HTMLAttributes<HTMLLabelElement> {
    children: ReactNode;
    htmlFor: string;
}

export function Label({ children, htmlFor, className, ...restProps }: ILabelProps) {
    return (
        <label className={twMerge("block text-sm  text-gray-700", className)} htmlFor={htmlFor} {...restProps}>
            {children}
        </label>
    );
}
