import { commonConstraints, commonTypes, commonUtils } from "@ui/common";
import { twMerge } from "@app/common";
import React, { HTMLAttributes } from "react";

interface IAvatarProps extends HTMLAttributes<HTMLDivElement> {
    url?: string;
    fallBackInitials: string;
    shape?: "square" | "circle";
    color?: commonTypes.TColor;
    size?: Exclude<commonTypes.TSizes, "lg">;
}

export function Avatar({ url, fallBackInitials, shape = "circle", color = "slate", size = "md", className, ...restProps }: IAvatarProps) {
    const initials =
        fallBackInitials.split(" ").length > 1
            ? fallBackInitials.split(" ")[0].charAt(0) + fallBackInitials.split(" ")[1]?.charAt(0)
            : fallBackInitials.charAt(0);

    const getSize = () => {
        switch (size) {
            case "xs":
                return "w-6 h-6";
            case "md":
                return "w-12 h-12";
            case "xl":
                return "w-16 h-16";
        }
    };

    const getColor = () => {
        switch (color) {
            case "blue":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "purple":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "yellow":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "gray":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "green":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "red":
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
            case "slate":
            default:
                return [
                    commonUtils.getColorClassNames("white").textColor,
                    commonUtils.getColorClassNames(color, commonConstraints.colorPalette.background).bgColor,
                ];
        }
    };

    return (
        <div
            className={twMerge(
                "text-xs bg-gray-200 text-gray-700 uppercase flex items-center justify-center overflow-hidden",
                shape === "circle" ? "rounded-full" : "rounded-md",
                getSize(),
                getColor(),
                className
            )}
            {...restProps}>
            {url ? <img src={`${url}`} alt={fallBackInitials} className="object-cover h-14 w-14" /> : <div>{initials}</div>}
        </div>
    );
}
