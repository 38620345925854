import {
    AdjustmentsHorizontalIcon,
    ArrowsUpDownIcon,
    // AdjustmentsIcon,
    // ArrowsExpandIcon,
    BriefcaseIcon,
    BuildingLibraryIcon,
    BuildingOffice2Icon,
    ChartBarIcon,
    // ChatBubbleLeftIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    // ClipboardIcon,
    CogIcon,
    EnvelopeIcon,
    // CollectionIcon,
    // DatabaseIcon,
    // DocumentSearchIcon,
    HomeIcon,
    IdentificationIcon,
    // LibraryIcon,
    // MailIcon,
    NewspaperIcon,
    // OfficeBuildingIcon,
    QuestionMarkCircleIcon,
    ServerIcon,
    // SupportIcon,
    // TemplateIcon,
    UserCircleIcon,
    UserGroupIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/solid";

/**
 * Mapping between icon name and Icon component.
 */
OutlineIcon.map = {
    "briefcase": BriefcaseIcon,
    "chart-bar": ChartBarIcon,
    "chat": ChatBubbleLeftIcon,
    "cog": CogIcon,
    // "database": DatabaseIcDon,
    // "collection": Collect,
    "server": ServerIcon,
    // "clipboard-list": ClipboardListIcon,
    "library": BuildingLibraryIcon,
    "office-building": BuildingOffice2Icon,
    // "document-search": DocumentSearchIcon,
    "home": HomeIcon,
    "question-mark-circle": QuestionMarkCircleIcon,
    "user-circle": UserCircleIcon,
    "x": XMarkIcon,
    "chevron-up": ChevronUpIcon,
    "chevron-down": ChevronDownIcon,
    "chevron-left": ChevronLeftIcon,
    "chevron-right": ChevronRightIcon,
    "support": ChatBubbleLeftIcon,
    "identification": IdentificationIcon,
    "user-group": UserGroupIcon,
    "users": UsersIcon,
    // "template": TemplateIc,
    "newspaper": NewspaperIcon,
    "adjustments": AdjustmentsHorizontalIcon,
    "arrows-expand": ArrowsUpDownIcon,
    "mail": EnvelopeIcon,
};

export interface IOutlineIconProps {
    [key: string]: any;
    name: keyof typeof OutlineIcon.map;
}

export function OutlineIcon(props: IOutlineIconProps) {
    const { name, ...iconProps } = props;
    const hasIcon = name in OutlineIcon.map;
    const Icon = OutlineIcon.map[name];

    if (!hasIcon) {
        throw Error(`Invalid icon name: ${name}`);
    }

    return <Icon {...iconProps} />;
}
