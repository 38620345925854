import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { useForwardedRef } from "../common/hooks";

export interface IInputProps {
    [key: string]: any;
    prefixIcon?: string | React.ReactElement;
    suffixIcon?: React.ReactElement;
    type?: React.HTMLInputTypeAttribute;
    label?: string;
    name: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    isDisabled?: boolean;
}

export const Input = forwardRef(
    (
        { label, name, prefixIcon, readOnly, isDisabled, suffixIcon, type, placeholder, ...restProps }: IInputProps,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => {
        const [showPassword, setShowPassword] = React.useState<boolean>(false);
        const togglePassword = () => setShowPassword((prevState) => !prevState);

        const safeRef = useForwardedRef(ref);

        return (
            <div className={classNames({ relative: !!prefixIcon || !!suffixIcon })}>
                {prefixIcon && <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">{prefixIcon}</div>}

                <input
                    {...restProps}
                    ref={safeRef}
                    id={name}
                    name={name}
                    readOnly={readOnly}
                    disabled={isDisabled}
                    type={showPassword && type === "password" ? "text" : type}
                    autoComplete="current-password"
                    placeholder={placeholder ?? label}
                    className={classNames(
                        "text-slate-600 appearance-none block w-full p-2 border transition-all duration-200 rounded-md placeholder-gray-400 sm:text-sm",
                        { "pl-10": prefixIcon },
                        { "bg-inherit border-transparent p-0": readOnly },
                        {
                            "border-gray-300 p-2 focus:ring-1.5 focus:ring-purple-800 focus:border-purple-800": !readOnly,
                        }
                    )}
                />

                {!!suffixIcon && (
                    <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">{suffixIcon}</div>
                )}
                {type === "password" && (
                    <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">
                        {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                        ) : (
                            <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                        )}
                    </div>
                )}
            </div>
        );
    }
);
