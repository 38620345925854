import classNames from "classnames";
import React from "react";

export interface IFormProps {
    fullWidth?: boolean;
    children: React.ReactNode | undefined;
    onSubmit: any;
    plain?: boolean;
}

export function Form({ fullWidth = true, children, onSubmit, plain = false }: IFormProps) {
    return plain ? (
        <form onSubmit={onSubmit} className="w-full">
            {children}
        </form>
    ) : (
        <div className="px-0 py-5 md:p-0 sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className={classNames("md:mt-0", { "md:col-span-3": fullWidth }, { "md:col-span-2": !fullWidth })}>
                    <form
                        onSubmit={onSubmit}
                        className={classNames(
                            "w-full",
                            "grid gap-y-4 gap-x-4",
                            "grid gap-y-4 gap-x-4",
                            "grid gap-y-4 gap-x-4",
                            { "grid-cols-4": fullWidth },
                            { "grid-cols-4": fullWidth },
                            { "grid-cols-4": fullWidth },
                            { "grid-cols-2": !fullWidth }
                        )}>
                        {children}
                    </form>
                </div>
            </div>
        </div>
    );
}
