/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Console, IConfig as IConsoleConfig, IConsoleContract } from "./Console";

export type Subtract<T, K> = Omit<T, keyof K>;

export interface IConfig extends Subtract<IConsoleConfig, "console"> {
    console?: IConsoleContract;
}

/**
 * Web console adapter
 */
export class WebConsole extends Console {
    constructor(config: IConfig = {}) {
        super({
            ...config,
            console: config.console || window.console || null,
        });
    }
}
